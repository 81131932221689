import { PaginationType } from "../../Types/APITypes";
import {
  CommunityCategory,
  CommunityComments,
  CommunityRequest,
} from "../../Types/Community";
import API from "./API";

class CommunityAPI extends API {
  static get_categories = (pagination?: PaginationType) => {
    return this.getRequest(
      `manage/community/categories?page=${
        pagination?.current_page || 1
      }&per_page=${pagination?.per_page || 10}${
        pagination?.search_word ? `&query=${pagination.search_word}` : ""
      } `,
      true
    );
  };

  static add_category = (category: CommunityCategory) => {
    return this.postRequest(
      "manage/community/categories/create",
      category,
      true
    );
  };

  static update_category = (category: CommunityCategory) => {
    return this.putRequest(
      `manage/community/categories/${category.id}/update`,
      category,
      true
    );
  };

  static delete_category = (category: CommunityCategory) => {
    return this.deleteRequest(
      `manage/community/categories/${category.id}/delete`,
      category,
      true
    );
  };

  static add_request = (request: CommunityRequest) => {
    const formdata = new FormData();
    type postType = keyof CommunityRequest;

    for (const key in request) {
      if (Object.prototype.hasOwnProperty.call(request, key)) {
        const element = request[key as postType];
        formdata.append(key, (element || "")?.toString());
      }
    }

    formdata.delete("poster");
    formdata.delete("poster_file");
    if (request.poster_file) formdata.append("poster", request.poster_file);

    return this.postFileRequest(
      "manage/community/requests/create",
      formdata,
      true
    );
  };

  static get_request = (id: number) => {
    return this.getRequest(`community/requests/${id}/show`, true);
  };

  static get_request_comments = (id: number, pagination?: PaginationType) => {
    return this.getRequest(
      `manage/community/requests/${id}/comments?page=${
        pagination?.current_page || 1
      }&per_page=${pagination?.per_page || 10}${
        pagination?.search_word ? `&query=${pagination.search_word}` : ""
      } `,
      true
    );
  };

  static update_post = (post: CommunityRequest) => {
    const formdata = new FormData();
    type postType = keyof CommunityRequest;

    for (const key in post) {
      if (Object.prototype.hasOwnProperty.call(post, key)) {
        const element = post[key as postType];
        formdata.append(key, (element || "")?.toString());
      }
    }

    formdata.delete("poster");
    formdata.delete("poster_file");
    if (post.poster_file) {
      formdata.append("poster", post.poster_file);
      return this.putFileRequest(
        `manage/community/requests/${post.id}/update`,
        formdata,
        true
      );
    } else {
      const { poster, ...rest } = post;
      return this.putRequest(
        `manage/community/requests/${post.id}/update`,
        rest,
        true
      );
    }
  };

  static get_posts = (pagination?: PaginationType) => {
    return this.getRequest(
      `manage/community/requests?page=${
        pagination?.current_page || 1
      }&per_page=${pagination?.per_page || 10}${
        pagination?.search_word ? `&query=${pagination.search_word}` : ""
      } `,
      true
    );
  };

  static update_post_status = (id: number, new_status: boolean) => {
    let link = "";

    switch (new_status) {
      case true:
        link = "publish";
        break;
      case false:
        link = "draft";
        break;

      default:
        break;
    }

    return this.patchRequest(
      `manage/community/requests/${id}/${link}`,
      {},
      true
    );
  };

  static delete_post = (id: number) => {
    return this.deleteRequest(
      `manage/community/requests/${id}/delete`,
      {},
      true
    );
  };

  static get_post_comments = (pagination?: PaginationType) => {
    return this.getRequest(
      `manage/community/comments?page=${
        pagination?.current_page || 1
      }&per_page=${pagination?.per_page || 10}${
        pagination?.search_word ? `&query=${pagination.search_word}` : ""
      } `,
      true
    );
  };

  static delete_post_comment = (comment: CommunityComments) => {
    return this.deleteRequest(
      `manage/community/requests/${comment.community_request_id}/comments/${comment.id}/delete`,
      {},
      true
    );
  };

  static disable_post_comment = (comment: CommunityComments) => {
    return this.patchRequest(
      `manage/community/requests/${comment.community_request_id}/comments/${comment.id}/disable`,
      {},
      true
    );
  };

  static publish_post_comment = (comment: CommunityComments) => {
    return this.patchRequest(
      `manage/community/requests/${comment.community_request_id}/comments/${comment.id}/publish`,
      {},
      true
    );
  };

  static update_post_comment = (comment: CommunityComments) => {
    return this.putRequest(
      `manage/community/requests/${comment.community_request_id}/comments/${comment.id}/update`,
      comment,
      true
    );
  };
}

export default CommunityAPI;
