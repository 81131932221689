import { Navigate, Outlet } from "react-router-dom";
import LayoutApp from "../Layouts/Application/LayoutApp";
import ErrorPage from "../Layouts/Application/ErrorPage";
import Login from "../pages/Auth/Login";
import Dashboard from "../pages/Dashboard/Dashboard";
import Settings from "../pages/Settings/Settings";
import GuestLayoutApp from "../Layouts/Application/GuestLayoutApp";
import PasswordReset from "../pages/Auth/PasswordReset";
import NewPassword from "../pages/Auth/NewPassword";

import CategoryList from "../pages/Blogs/Category/CategoryList";
import AddOrUpdateCategory from "../pages/Blogs/Category/AddOrUpdateCategory";
import AddPost from "../pages/Blogs/Post/AddOrUpdatePost";
import PostList from "../pages/Blogs/Post/PostList";
import CommentList from "../pages/Blogs/Comments/CommentList";
import AddOrUpdateComment from "../pages/Blogs/Comments/AddOrUpdateComment";

import CommunityCategoryList from "../pages/Community/Category/CategoryList";
import CommunityAddOrUpdateCategory from "../pages/Community/Category/AddOrUpdateCategory";
import CommunityPostList from "../pages/Community/Post/PostList";
import CommunityCommentList from "../pages/Community/Comments/CommentList";
import CommunityAddOrUpdateComment from "../pages/Community/Comments/AddOrUpdateComment";


import { PostProvider } from "../context/postContext";
import UserList from "../pages/Users/pages/UserList";
import UserDetails from "../pages/Users/pages/UserDetails";
import { PostCommentProvider } from "../context/postCommentContext";
import CommunityRequestCommentList from "../pages/Community/Post/CommunityRequestCommentList";
import BlogPostCommentList from "../pages/Blogs/Post/BlogPostCommentList";

const routes = (isLoggedIn: boolean) => [
  {
    path: "/app",
    element: isLoggedIn ? <LayoutApp /> : <Navigate to="/login" />,
    errorElement: <ErrorPage />,
    children: [
      { path: "dashboard", element: <Dashboard /> },
      { path: "settings", element: <Settings /> },
      {
        path: "blog",
        element: <Outlet />,
        children: [
          {
            path: "category",
            element: <Outlet />,
            children: [
              { index: true, element: <CategoryList /> },
              { path: "create", element: <AddOrUpdateCategory /> },
            ],
          },
          {
            path: "comments",
            element: (
              <PostCommentProvider>
                <Outlet />
              </PostCommentProvider>
            ),
            children: [
              { index: true, element: <CommentList /> },
              { path: "create", element: <AddOrUpdateComment /> },
            ],
          },
          {
            path: "posts",
            element: (
              <PostProvider>
                {" "}
                <Outlet />{" "}
              </PostProvider>
            ),
            children: [
              { index: true, element: <PostList /> },
              {
                path: "create",
                element: <AddPost />,
              },
              {
                path: ":id/edit",
                element: <AddPost />,
              },
              {
                path: ":id/comments",
                element: <BlogPostCommentList />,
              },
            ],
          },
        ],
      },
      {
        path: "community",
        element: <Outlet />,
        children: [
          {
            path: "category",
            element: <Outlet />,
            children: [
              { index: true, element: <CommunityCategoryList /> },
              { path: "create", element: <CommunityAddOrUpdateCategory /> },
            ],
          },
          {
            path: "comments",
            element: (
              <PostCommentProvider>
                <Outlet />
              </PostCommentProvider>
            ),
            children: [
              { index: true, element: <CommunityCommentList /> },
              { path: "create", element: <CommunityAddOrUpdateComment /> },
            ],
          },
          {
            path: "requests",
            element: (
              <PostProvider>
                {" "}
                <Outlet />{" "}
              </PostProvider>
            ),
            children: [
              { index: true, element: <CommunityPostList /> },
              {
                path: ":id/comments",
                element: <CommunityRequestCommentList />,
              },
            ],
          },
        ],
      },
      {
        path: "users",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: (
              <PostProvider>
                {" "}
                <UserList />{" "}
              </PostProvider>
            ),
          },
          {
            path: ":id/view",
            element: <UserDetails />,
          },
        ],
      },
      // { path: "/", element: <Navigate to="/app/dashboard" /> },
    ],
  },

  {
    path: "/",
    element: !isLoggedIn ? (
      <GuestLayoutApp />
    ) : (
      <Navigate to="/app/dashboard" />
    ),
    errorElement: <ErrorPage />,
    children: [
      { path: "login", element: <Login /> },
      { path: "/password_reset/:token", element: <NewPassword /> },
      { path: "/password_reset", element: <PasswordReset /> },
      { path: "/", element: <Login /> },
    ],
  },
];

export default routes;
