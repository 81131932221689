import React, { ReactNode, useState } from "react";

export interface BreadcrumbsItemType {
  label: string;
  icon?: JSX.Element;
  is_link: boolean;
  href?: string;
}

export interface BreadcrumbsContextValueType {
  breadcrumbs_items?: BreadcrumbsItemType[] | null;
  setBreadcrumbs_items?: React.Dispatch<
    React.SetStateAction<BreadcrumbsItemType[] | undefined>
  >;
}

export const BreadcrumbsContext = React.createContext<
  BreadcrumbsContextValueType | null | undefined
>({
  breadcrumbs_items: null,
});

export const BreadcrumbsProvider = ({ children }: { children: ReactNode }) => {
  const [breadcrumbs_items, setBreadcrumbs_items] =
    useState<BreadcrumbsItemType[]>();

  return (
    <BreadcrumbsContext.Provider
      value={{
        breadcrumbs_items,
        setBreadcrumbs_items,
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export default BreadcrumbsProvider;
