import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { SlOptions } from "react-icons/sl";
import clsx from "clsx";
import { Post } from "../../../Types/Blogs";
import { Link, useNavigate } from "react-router-dom";
import {
  MdDelete,
  MdEdit,
  MdPreview,
  MdPublish,
  MdUnpublished,
} from "react-icons/md";
import app from "../../../config/app";
import Modal from "../../../UI/Modal/Modal";
import HandleStatusPost from "./HandleStatusPost";
import BlogPostStatusType from "../../../Types/Blogs/BlogPostStatus";
import HandleDeletePost from "./HandleDeletePost";

type MenuPostProps = {
  post: Post;
  fetchData: () => void;
};

export default function MenuPost({ post, fetchData }: MenuPostProps) {
  const navigate = useNavigate()

  const [modal_status, setModalStatus] = useState<{
    open: boolean;
    post?: Post | null;
  }>({ open: false, post: null });
  const [modal_delete, setModalDelete] = useState<{
    open: boolean;
    post?: Post | null;
  }>({ open: false, post: null });

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Modal
        open={modal_status.open}
        onClose={() => {
          setModalStatus({ open: false });
        }}
      >
        <HandleStatusPost
          onClose={() => {
            fetchData();
            setModalStatus({ open: false });
          }}
          initialState={modal_status.post}
        />
      </Modal>
      <Modal
        open={modal_delete.open}
        onClose={() => {
          setModalDelete({ open: false });
        }}
      >
        <HandleDeletePost
          onClose={() => {
            fetchData();
            setModalDelete({ open: false });
          }}
          initialState={modal_delete.post}
        />
      </Modal>

      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-900">
          <SlOptions
            className="w-4 h-4 -mr-1 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }: { active: boolean }) => (
                <Link
                  to={`/app/blog/posts/${post.id}/edit`}
                  className={clsx(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    " px-4 py-2 text-sm flex items-center gap-2 w-full"
                  )}
                >
                  <MdEdit />
                  Editer
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }: { active: boolean }) => (
                <button
                  type="button"
                  onClick={() =>
                    navigate(`/app/blog/posts/${post.id}/comments`)
                  }
                  className={clsx(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    " px-4 py-2 text-sm flex items-center gap-2 w-full"
                  )}
                >
                  <MdPreview />
                  Voir les commentaires
                </button>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }: { active: boolean }) => (
                <button
                  type="button"
                  className={clsx(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    " px-4 py-2 text-sm flex items-center gap-2 w-full"
                  )}
                  onClick={() => setModalStatus({ open: true, post })}
                >
                  {post.status === BlogPostStatusType.DRAFT ? (
                    <>
                      <MdPublish />
                      Publier
                    </>
                  ) : (
                    <>
                      {" "}
                      <MdUnpublished />
                      Dépublier
                    </>
                  )}
                </button>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }: { active: boolean }) => (
                <button
                  onClick={() => setModalDelete({ open: true, post })}
                  type="button"
                  className={clsx(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    " px-4 py-2 text-sm flex items-center gap-2 w-full text-red-800"
                  )}
                >
                  <MdDelete />
                  Supprimer
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
