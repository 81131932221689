import React, { useCallback, useEffect, useState } from "react";
import Autocomplete from "react-autocomplete";
import { useDebounce } from "../../utils/hooks/useDebounce";
import BlogAPI from "../../services/api/BlogService";
import { PaginationType } from "../../Types/APITypes";
import { Category } from "../../Types/Blogs";

interface AutoCompleteCategoryProps {
  handleSelect: (value: any) => void;
  initial_value?: string;
}

export default function AutoCompleteCategory(props: AutoCompleteCategoryProps) {
  const { handleSelect, initial_value } = props;
  const [value, setValue] = useState(initial_value || "");

  const debounceKeyword = useDebounce(value);

  const [, setLoading] = useState(false);
  const [options, setOptions] = useState<Category[] | null>(null);

  const fetchData = useCallback((pagination?: PaginationType) => {
    setLoading(true);
    BlogAPI.get_categories(pagination)
      .then((response: any) => response?.data)
      .then((data: any) => {
        setOptions(data?.categories || null);
      })
      .catch((error) => {
        console.error("err => ", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData({ search_word: initial_value });
    setValue(initial_value || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initial_value]);

  useEffect(() => {
    fetchData({ search_word: debounceKeyword });
  }, [debounceKeyword, fetchData]);

  return (
    <Autocomplete
      getItemValue={(item) => item.title}
      items={options || []}
      renderItem={(item, isHighlighted) => (
        <div
          style={{ background: isHighlighted ? "lightgray" : "white" }}
          key={item.id}
          className="px-2 py-2 cursor-pointer"
        >
          {item.title}
        </div>
      )}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onSelect={(val) => {
        setValue(val);
        handleSelect(options?.find((option) => option.title === val));
      }}
      onMenuVisibilityChange={(isOpen) => {
        if (
          !isOpen &&
          options?.find((option) => option.title === value) === undefined
        ) {
          setValue(initial_value || "");
        }
      }}
      inputProps={{
        // readOnly: true,
        className: "form-input w-full",
      }}
      wrapperStyle={{
        boxShadow: "none",
      }}
    />
  );
}
