import { Fragment, useContext, useState } from "react";
import { MdBlock, MdCancel, MdCheckCircle, MdStopCircle } from "react-icons/md";
import { NotifyContext } from "../../../context/notifyContext";
import UserAPI from "../../../services/api/UserService";
import { Tutor, UserStatus } from "../../../Types/Users";
import Alert from "../../../UI/Alert/Alert";
import ButtonLoading from "../../../UI/Button/ButtonLoading";
import Modal from "../../../UI/Modal/Modal";
import AcceptTutor from "./AcceptTutor";

type TutorDetailsProps = {
  tutor: Tutor;
  fetchData: () => void;
};

const permissions = [
  {
    tag: "Blog",
    permissions: [
      // { title: "Create blog posts", code: "create_blog_posts" },
      // { title: "Edit blog posts", code: "edit_blog_posts" },
      // { title: "Delete blog posts", code: "delete_blog_posts" },
      // { title: "Approve blog posts", code: "approve_blog_posts" },
      // { title: "Moderate blog comments", code: "moderate_blog_comments" },
      { title: "Comment a blog", code: "create_blog_comments" },
    ],
  },
  {
    tag: "Community",
    permissions: [
      { title: "View community requests", code: "view_community_requests" },
      { title: "Create community requests", code: "create_community_requests" },
      { title: "Edit community requests", code: "edit_community_requests" },
      { title: "Delete community requests", code: "delete_community_requests" },
      // {
      //   title: "Approve community requests",
      //   code: "approve_community_requests",
      // },
      // {
      //   title: "Moderate community comments",
      //   code: "moderate_forum_comments",
      // },
      { title: "Comment a community", code: "create_forum_comments" },
    ],
  },
  // {
  //   tag: "Content",
  //   permissions: [
  //     { title: "Manage site content", code: "manage_site_content" },
  //   ],
  // },
  {
    tag: "Students",
    permissions: [
      { title: "View student profiles", code: "view_student_profiles" },
      // { title: "Create student profiles", code: "create_student_profiles" },
      // { title: "Edit student profiles", code: "edit_student_profiles" },
      // { title: "Delete student profiles", code: "delete_student_profiles" },
      // { title: "Approve student profiles", code: "approve_student_profiles" },
    ],
  },
  {
    tag: "Tutors",
    permissions: [
      { title: "View tutor profiles", code: "view_tutor_profiles" },
      // { title: "Create tutor profiles", code: "create_tutor_profiles" },
      { title: "Edit tutor profiles", code: "edit_tutor_profiles" },
      // { title: "Delete tutor profiles", code: "delete_tutor_profiles" },
      // { title: "Approve tutor profiles", code: "approve_tutor_profiles" },
    ],
  },
  // {
  //   tag: "Admins",
  //   permissions: [
  //     { title: "Manage all permissions", code: "manage_all_permissions" },
  //     { title: "Manage users", code: "manage_users" },
  //   ],
  // },
];

export default function TutorDetails({ tutor, fetchData }: TutorDetailsProps) {
  const [modal_confirm, setModalConfirm] = useState<{
    open: boolean;
  }>({ open: false });

  const [userPermissions, setUserPermissions] = useState(
    tutor?.permissions || []
  );

  const notify = useContext(NotifyContext);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleCheckboxChange = (tag: string, code: string) => {
    const updatedPermissions = [...userPermissions];
    const permissionIndex = updatedPermissions.findIndex(
      (permission) => permission === code
    );

    if (permissionIndex > -1) {
      updatedPermissions.splice(permissionIndex, 1);
    } else {
      updatedPermissions.push(code);
    }

    setUserPermissions(updatedPermissions);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Updated permissions:", userPermissions);
    // return;
    setLoading(true);
    UserAPI.update_user_permissions(tutor.user_id, userPermissions)
      .then((data) => {
        notify("Mise à jour des permissions effectuée.");
        setError("");
        fetchData();
      })
      .catch((error) => {
        setError(error);
        notify(error, "error");
      })
      .finally(() => {
        setLoading(false);
      });
    // Logic to update user permissions with userPermissions state
    console.log("Updated permissions:", userPermissions);
  };

  if (!tutor) return <></>;

  return (
    <Fragment>
      <Modal
        open={modal_confirm.open}
        title={`Confirmer ${tutor.first_name} ${tutor.last_name}`}
        onClose={() => {
          setModalConfirm({ open: false });
        }}
      >
        <AcceptTutor
          onClose={() => {
            fetchData();
            setModalConfirm({ open: false });
          }}
          tutor={tutor}
        />
      </Modal>

      {error && <Alert color="error">{error}</Alert>}

      <div className="container mx-auto my-5 p-5">
        <div className="md:flex no-wrap md:-mx-2 ">
          {/* <!-- Left Side --> */}
          <div className="w-full md:w-3/12 md:mx-2">
            {/* <!-- Profile Card --> */}
            <div className="bg-white p-3 border-t-4 border-green-400">
              <div className="image overflow-hidden">
                <img
                  className="h-auto w-full mx-auto"
                  src="https://lavinephotography.com.au/wp-content/uploads/2017/01/PROFILE-Photography-112.jpg"
                  alt=""
                />
              </div>
              <h1 className="text-gray-900 font-bold text-xl leading-8 my-1">
                {tutor.first_name} {tutor.last_name}
              </h1>
              <h3 className="text-gray-600 font-lg text-semibold leading-6">
                {tutor.last_degree}
              </h3>
              <div className="mt-3">
                <p className="text-xs text-gray-600">Tuteur pour :</p>
                <div className="mt-3">
                  {tutor.levels?.map((level) => (
                    <span key={level.id} className="bg-gray-500 py-1 px-2 mr-2 rounded text-white text-sm">
                      {level.title}
                    </span>
                  ))}
                </div>
              </div>
              <ul className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-5 divide-y rounded shadow-sm">
                <li className="flex items-center py-3">
                  <span>Statut</span>

                  <span className="ml-auto">
                    {tutor.user_status === UserStatus.PENDING && (
                      <span className="bg-green-500 py-1 px-2 rounded text-white text-sm">
                        En attente
                      </span>
                    )}

                    {tutor.user_status === UserStatus.VALIDATED && (
                      <span className="bg-green-500 py-1 px-2 rounded text-white text-sm">
                        Validé
                      </span>
                    )}

                    {tutor.user_status === UserStatus.BLOCKED && (
                      <span className="bg-red-500 py-1 px-2 rounded text-white text-sm">
                        Bloqué
                      </span>
                    )}

                    {tutor.user_status === UserStatus.REFUSED && (
                      <span className="bg-yellow-500 py-1 px-2 rounded text-white text-sm">
                        Refusé
                      </span>
                    )}

                    {tutor.user_status === UserStatus.CONFIRMED && (
                      <span className="bg-gray-500-500 py-1 px-2 rounded text-white text-sm">
                        En attente
                      </span>
                    )}
                  </span>
                </li>
                <li className="flex items-center py-3">
                  <span>Ajouté le</span>
                  <span className="ml-auto">
                    {new Date(tutor.created_at).toDateString()}
                  </span>
                </li>
                <li className="flex items-center justify-center py-3">
                  <a
                    href={`https://api.tanouacademy.com${tutor.resume?.url}`}
                    download
                    target="_blank"
                    className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600"
                  >
                    Télécharger le CV
                  </a>
                </li>
              </ul>
            </div>
            {/* <!-- End of profile card --> */}
            <div className="my-4"></div>

            {/* <!-- Friends card --> */}
            <div className="bg-white p-3 hover:shadow opacity-0">
              <div className="flex items-center space-x-3 font-semibold text-gray-900 text-xl leading-8">
                <span className="text-green-500">
                  <svg
                    className="h-5 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                </span>
                <span>Similar Profiles</span>
              </div>
              <div className="grid grid-cols-3">
                <div className="text-center my-2">
                  <img
                    className="h-16 w-16 rounded-full mx-auto"
                    src="https://cdn.australianageingagenda.com.au/wp-content/uploads/2015/06/28085920/Phil-Beckett-2-e1435107243361.jpg"
                    alt=""
                  />
                  <a href="#" className="text-main-color">
                    Kojstantin
                  </a>
                </div>
                <div className="text-center my-2">
                  <img
                    className="h-16 w-16 rounded-full mx-auto"
                    src="https://avatars2.githubusercontent.com/u/24622175?s=60&amp;v=4"
                    alt=""
                  />
                  <a href="#" className="text-main-color">
                    James
                  </a>
                </div>
                <div className="text-center my-2">
                  <img
                    className="h-16 w-16 rounded-full mx-auto"
                    src="https://lavinephotography.com.au/wp-content/uploads/2017/01/PROFILE-Photography-112.jpg"
                    alt=""
                  />
                  <a href="#" className="text-main-color">
                    Natie
                  </a>
                </div>
                <div className="text-center my-2">
                  <img
                    className="h-16 w-16 rounded-full mx-auto"
                    src="https://bucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com/public/images/f04b52da-12f2-449f-b90c-5e4d5e2b1469_361x361.png"
                    alt=""
                  />
                  <a href="#" className="text-main-color">
                    Casey
                  </a>
                </div>
              </div>
            </div>
            {/* <!-- End of friends card --> */}
          </div>
          {/* <!-- Right Side --> */}
          <div className="w-full md:w-9/12 mx-2 h-64">
            {/* <!-- Profile tab --> */}
            {/* <!-- About Section --> */}
            <div className="bg-white p-3 shadow-sm rounded-sm">
              <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                <span className="text-green-500">
                  <svg
                    className="h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    />
                  </svg>
                </span>
                <span className="tracking-wide">À propos du tuteur</span>
              </div>
              <div className="text-gray-700">
                <div className="grid md:grid-cols-2 text-sm">
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-2 font-semibold">Nom</div>
                    <div className="px-4 py-2">{tutor.first_name}</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-2 font-semibold">Prénom</div>
                    <div className="px-4 py-2">{tutor.last_name}</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-2 font-semibold">Genre</div>
                    <div className="px-4 py-2">
                      {tutor.civility === "M" ? "Homme" : "Femme"}
                    </div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-2 font-semibold">Contact No.</div>
                    <div className="px-4 py-2">{tutor.phone_number}</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-2 font-semibold">Adresse</div>
                    <div className="px-4 py-2">{tutor.address_line_1}</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-2 font-semibold">Email.</div>
                    {tutor.is_email_verified ? (
                      <div className="flex gap-2 items-center px-4 py-2">
                        <a
                          className="text-blue-800"
                          href={`mailto:${tutor.email}`}
                        >
                          {tutor.email}
                        </a>
                        <MdCheckCircle color="green" className="text-lg" />
                      </div>
                    ) : (
                      <div className="flex gap-2 items-center px-4 py-2">
                        <a
                          className="text-blue-800"
                          href={`mailto:${tutor.email}`}
                        >
                          {tutor.email}
                        </a>
                        <MdBlock
                          title="Email non confirmé"
                          color="red"
                          className="text-lg"
                        />
                      </div>
                    )}
                  </div>
                  <div className="grid grid-cols-2">
                    <div className="px-4 py-2 font-semibold">
                      Date de naissance
                    </div>
                    <div className="px-4 py-2">
                      {new Date(tutor.birth_date).toLocaleDateString()}
                    </div>
                  </div>
                </div>
              </div>
              {(tutor.user_status === UserStatus.PENDING ||
                tutor.user_status === UserStatus.CONFIRMED) && (
                <div className="flex justify-end gap-4">
                  <button
                    className="block  bg-green-600 text-white text-sm font-semibold rounded-lg hover:opacity-60 focus:outline-none focus:shadow-outline focus:opacity-60 hover:shadow-xs p-3 my-4"
                    onClick={() => setModalConfirm({ open: true })}
                  >
                    Confirmer le tuteur
                  </button>
                  <button className="block  bg-red-600 text-white text-sm font-semibold rounded-lg hover:opacity-60 focus:outline-none focus:shadow-outline focus:opacity-60 hover:shadow-xs p-3 my-4">
                    Rejeter la demande
                  </button>
                </div>
              )}
            </div>
            {/* <!-- End of about section --> */}

            <div className="my-4"></div>

            {/* <!-- Permissions Section --> */}
            <div className="bg-white p-3 shadow-sm rounded-sm">
              <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                <span className="text-green-500">
                  <svg
                    className="h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    />
                  </svg>
                </span>
                <span className="tracking-wide">Permissions du tuteur</span>
              </div>
              <div className="text-gray-700">
                <form className="mt-4 mb-8" onSubmit={handleSubmit}>
                  {permissions.map((group) => (
                    <div key={group.tag} className="mb-4">
                      <h2 className="text-base font-semibold mb-2">
                        {group.tag}
                      </h2>
                      <div className="grid grid-cols-3 gap-4">
                        {group.permissions.map((permission) => (
                          <label
                            key={permission.code}
                            className="flex items-center text-sm"
                          >
                            <input
                              type="checkbox"
                              className="form-checkbox mr-2"
                              checked={userPermissions.some(
                                (p) => p === permission.code
                              )}
                              onChange={() =>
                                handleCheckboxChange(group.tag, permission.code)
                              }
                            />
                            {permission.title}
                          </label>
                        ))}
                      </div>
                    </div>
                  ))}
                  <ButtonLoading
                    type="submit"
                    loading={loading}
                    label="Save permissions"
                    className="mt-4"
                  />
                  {/* <button
                    type="submit"
                    className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600"
                  >
                    Save Permissions
                  </button> */}
                </form>
              </div>
            </div>
            {/* <!-- End of permissions --> */}

            <div className="my-4"></div>

            {/* <!-- Experience and education --> */}
            <div className="opacity-0 bg-white p-3 shadow-sm rounded-sm">
              <div className="grid grid-cols-2">
                <div>
                  <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                    <span className="text-green-500">
                      <svg
                        className="h-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                    </span>
                    <span className="tracking-wide">Experience</span>
                  </div>
                  <ul className="list-inside space-y-2">
                    <li>
                      <div className="text-teal-600">
                        Owner at Her Company Inc.
                      </div>
                      <div className="text-gray-500 text-xs">
                        March 2020 - Now
                      </div>
                    </li>
                    <li>
                      <div className="text-teal-600">
                        Owner at Her Company Inc.
                      </div>
                      <div className="text-gray-500 text-xs">
                        March 2020 - Now
                      </div>
                    </li>
                    <li>
                      <div className="text-teal-600">
                        Owner at Her Company Inc.
                      </div>
                      <div className="text-gray-500 text-xs">
                        March 2020 - Now
                      </div>
                    </li>
                    <li>
                      <div className="text-teal-600">
                        Owner at Her Company Inc.
                      </div>
                      <div className="text-gray-500 text-xs">
                        March 2020 - Now
                      </div>
                    </li>
                  </ul>
                </div>
                <div>
                  <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                    <span className="text-green-500">
                      <svg
                        className="h-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                        <path
                          fill="#fff"
                          d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                        />
                      </svg>
                    </span>
                    <span className="tracking-wide">Education</span>
                  </div>
                  <ul className="list-inside space-y-2">
                    <li>
                      <div className="text-teal-600">
                        Masters Degree in Oxford
                      </div>
                      <div className="text-gray-500 text-xs">
                        March 2020 - Now
                      </div>
                    </li>
                    <li>
                      <div className="text-teal-600">
                        Bachelors Degreen in LPU
                      </div>
                      <div className="text-gray-500 text-xs">
                        March 2020 - Now
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- End of Experience and education grid --> */}
            </div>
            {/* <!-- End of profile tab --> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
