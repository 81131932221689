import { useContext, useEffect } from "react";
import {
  BreadcrumbsContext,
  BreadcrumbsItemType,
} from "../../context/BreadcrumbsContext";

function useBreadcrumbs(items: BreadcrumbsItemType[]) {
  const breadcrumbsContextValue = useContext(BreadcrumbsContext);

  useEffect(() => {
    if (breadcrumbsContextValue?.setBreadcrumbs_items) {
      breadcrumbsContextValue?.setBreadcrumbs_items([...items]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default useBreadcrumbs;
