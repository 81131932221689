import { Image } from ".";
import { EducationLevel, EducationSystem } from "./Education";

export enum UserStatus {
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  VALIDATED = "VALIDATED",
  REFUSED = "REFUSED",
  BLOCKED = "BLOCKED",
}

export interface User {
  id: string | number;
  role: string;
  data: Tutor | Student;
  diplayed_role: string;
}

export interface Tutor {
  id: number;
  user_id: number;
  email?: string;
  first_name?: string;
  last_name?: string;
  avatar?: Image;
  civility?: "M" | "F";
  is_email_verified?: boolean;
  phone_number?: string;
  birth_date: string;
  hobby?: string;
  address_line_1?: string;
  zip_code?: string;
  city?: string;
  country?: string;
  last_degree?: string;
  resume?: Image;
  levels?: Array<EducationLevel>;
  permissions?: Array<string>;
  user_status?: "PENDING" | "CONFIRMED" | "VALIDATED" | "REFUSED" | "BLOCKED";
  user_validated_at?: string;
  user_confirmed_at?: string;
  user_refused_at?: string;
  created_at: string;
  updated_at: string;
}

export interface Student {
  id: number;
  user_id: number;
  email?: string;
  first_name?: string;
  last_name?: string;
  avatar?: Image;
  civility?: "M" | "F";
  is_email_verified?: boolean;
  phone_number?: string;
  birth_date: string;
  hobby?: string;
  address_line_1?: string;
  zip_code?: string;
  city?: string;
  country?: string;
  permissions?: Array<string>;
  educational_system_id?: number;
  current_level_id?: number;
  previous_level_id?: number;
  educational_system?: EducationSystem;
  current_level?: EducationLevel;
  previous_level?: EducationLevel;
  user_status: "PENDING" | "CONFIRMED" | "VALIDATED" | "REFUSED" | "BLOCKED";
  user_validated_at?: string;
  user_confirmed_at?: string;
  user_refused_at?: string;
  created_at: string;
  updated_at?: string;
}
