import React, { Fragment } from "react";
import { BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom";
import { BreadcrumbsItemType } from "../../context/BreadcrumbsContext";

export interface BreadcrumbPropsTypes {
  items: BreadcrumbsItemType[];
}
export default function Breadcrumb(props: BreadcrumbPropsTypes) {
  const { items } = props;
  return (
    <div className="rounded-full bg-white px-4 py-2 hidden sm:flex items-center gap-x-4 ">
      {items.map((item, index) => {
        const { label, icon, is_link, href } = item;
        return (
          <Fragment key={item.label}>
            {is_link ? (
              <Link
                to={href || ""}
                className="flex items-center gap-x-2 text-xs text-secondary"
              >
                {icon} {label}
              </Link>
            ) : (
              <p className="flex items-center gap-x-2 text-xs text-[#58595f]">
                {icon} {label}
              </p>
            )}
            {index < items.length - 1 ? (
              <BiChevronRight className="text-[#58595f]" />
            ) : null}
          </Fragment>
        );
      })}
    </div>
  );
}
