import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { IconContext } from "react-icons";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./routes/routes";
import SideBarProvider from "./context/SideBarContext";
import BreadcrumbsProvider from "./context/BreadcrumbsContext";
import { isAuth } from "./utils/functions/auth";
import { NotifyProvider } from "./context/notifyContext";

const router = createBrowserRouter(routes(isAuth()));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <NotifyProvider>
      <BreadcrumbsProvider>
        <SideBarProvider>
          <IconContext.Provider
            value={{
              className: "react-icons",
              style: { verticalAlign: "middle" },
            }}
          >
            <RouterProvider router={router} />
          </IconContext.Provider>
        </SideBarProvider>
      </BreadcrumbsProvider>
    </NotifyProvider>
  </React.StrictMode>
);
