import React from "react";
import useBreadcrumbs from "../../utils/hooks/useBreadcrumbs";
import { RiDashboardFill } from "react-icons/ri";

export default function Dashboard() {
  useBreadcrumbs([
    {
      label: "Tableau de bord",
      icon: <RiDashboardFill />,
      is_link: false,
      href: "/app/blogs",
    },
  ]);

  return <div>Dashboard</div>;
}
