import { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputField from "../../UI/Form/InputField";
import AuthAPI from "../../services/api/AuthService";

import password_reset_img from "../../assets/images/password_reset.jpg";
import logo from "../../assets/images/logo_transparent.png";
import { Link } from "react-router-dom";

export default function PasswordReset() {
  const validators = {
    email: Yup.string()
      .email("Veuillez entrer une adresse mail correcte")
      .required("Veuillez entrer votre adresse mail"),
  };

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [mail_is_sent, setMailIsSent] = useState<boolean>(false);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape(validators),
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      setLoading(true);
      setError("");

      AuthAPI.send_mail_password_reset(values.email)
        .then((data) => {
          setMailIsSent(true);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <div className="h-screen bg-slate-200 flex flex-col items-center justify-center px-3 pt-8 sm:pt-0">
      <div className="h-24 mb-10">
        <img src={logo} alt="logo" className="w-full h-full object-cover " />
      </div>

      <div className="rounded-lg shadow-xl overflow-hidden bg-white max-w-3xl flex  ">
        <div className="flex-1  h-full hidden sm:block">
          <img
            src={password_reset_img}
            alt="password reset"
            className="h-full w-full object-cover"
          />
        </div>

        <div className="flex-1 px-8 py-4 flex flex-col justify-center items-start ">
          <h1 className="font-bold text-2xl mb-2 ">
            {mail_is_sent
              ? "Vérifier votre courrier"
              : "Réinitialiser votre mot de passe"}
          </h1>

          {mail_is_sent ? (
            <div className="mt-4">
              <p>
                Consultez votre courrier électronique pour trouver un lien vous
                permettant de réinitialiser votre mot de passe. S'il n'apparaît
                pas au bout de quelques minutes, vérifiez votre dossier de
                spams.
              </p>
              <Link
                to={"/login"}
                className="border-primary text-primary border rounded hover:bg-primary-50 block w-max mt-6 px-3 py-2 font-medium"
              >
                Retourner à la connexion
              </Link>
            </div>
          ) : (
            <>
              <small className="text-black/80">
                {" "}
                Saisissez l'adresse électronique vérifiée de votre compte
                utilisateur et nous vous enverrons un lien de réinitialisation
                du mot de passe.
              </small>
              <form className="w-full" onSubmit={formik.handleSubmit}>
                <div
                  className={`mt-6 ${
                    formik.touched.email &&
                    formik.errors.email &&
                    " text-red-500 w-full "
                  } `}
                >
                  <div className="mt-1 rounded-md shadow-sm w-full">
                    <InputField
                      type={"email"}
                      name={"email"}
                      id="email"
                      placeholder={"ex. johndoe@gmail.com"}
                      onBlur={formik.handleBlur}
                      value={formik.values.email ?? ""}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="text-red-500 mt-1">{error ? error : ""}</div>
                  {
                    <div className="mt-1 text-red-500 text-sm">
                      {" "}
                      {formik.touched.email && formik.errors.email
                        ? formik.errors.email
                        : ""}
                    </div>
                  }
                </div>

                <button
                  type={loading ? "button" : "submit"}
                  className="bg-primary hover:bg-primary-800 transition font-medium px-3 py-2 flex items-center gap-x-3 justify-center rounded-lg w-full mt-8 "
                >
                  Envoyer
                  {loading && (
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                </button>

                <Link
                  to={"/login"}
                  type={loading ? "button" : "submit"}
                  className="text-primary hover:underline mt-2 "
                >
                  Se connecter
                </Link>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
