import { useState } from "react";
import logo from "../../assets/logo.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputField from "../../UI/Form/InputField";
import FormLabel from "../../UI/Form/FormLabel";
import AuthAPI from "../../services/api/AuthService";
import { GLOBAL_ERROR } from "../../config/constants";
import { setToken, setUserInfo } from "../../utils/functions/auth";
import { Link } from "react-router-dom";

export default function Login() {
  const validators = {
    email: Yup.string()
      .email("Veuillez entrer une adresse mail correcte")
      .required("Veuillez entrer votre adresse mail"),

    password: Yup.string()
      .required("Veuillez entrer votre mot de passse.")
      .min(6, "Le mot de passe doit contenir au moins 6 caractères."),
  };

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape(validators),
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      setLoading(true);
      setError("");

      fetch("https://ipapi.co/json/")
        .then((res) => res.json())
        .then((res) => {
          AuthAPI.login({
            ...values,
            location: {
              ip: res.ip,
              country_code: res.country,
            },
          })
            .then((data: any) => {
              setToken(data?.token);

              setUserInfo(data?.data.user);

              window.location.reload();
            })
            .catch((error) => {
              setError(error);
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch(() => {
          setError(GLOBAL_ERROR);
          setLoading(false);
        });
    },
  });

  return (
    <div className="flex items-start h-screen px-3 pt-8 bg-slate-200 sm:items-center sm:justify-center sm:pt-0">
      <div className="rounded-lg shadow-xl overflow-hidden bg-white w-[512px] ">
        <div className="flex flex-col items-center justify-center gap-4 px-8 py-4 sm:flex-row bg-primary ">
          <div className="w-10 h-10 rounded-full">
            <img src={logo} alt="logo" className="object-cover w-full h-full" />
          </div>
          <div>
            <h1 className="font-bold ">
              Connectez-vous à votre compte ci-dessous
            </h1>
            <p className="text-xs font-light ">
              Bienvenue dans votre administration
            </p>
          </div>
        </div>
        <form className="px-8 py-3" onSubmit={formik.handleSubmit}>
          <div className="mt-2 text-red-500">{error ? error : ""}</div>
          <div
            className={`mt-6 ${
              formik.touched.email && formik.errors.email && " text-red-500 "
            } `}
          >
            <FormLabel htmlFor="email">Nom d'uitilisateur</FormLabel>

            <div className="mt-1 rounded-md shadow-sm">
              <InputField
                type={"email"}
                name={"email"}
                id="email"
                placeholder={"ex. johndoe@gmail.com"}
                onBlur={formik.handleBlur}
                value={formik.values.email ?? ""}
                onChange={formik.handleChange}
              />
            </div>
            {
              <div className="mt-1 text-sm text-red-500">
                {" "}
                {formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : ""}
              </div>
            }
          </div>

          <div
            className={`mt-6 ${
              formik.touched.password &&
              formik.errors.password &&
              " text-red-500 "
            } `}
          >
            <div className="flex items-center justify-between gap-2">
              <FormLabel htmlFor="password">Mot de passe</FormLabel>

              <Link
                to={"/password_reset"}
                className="text-secondary hover:underline"
              >
                Mot de passe oublié ?
              </Link>
            </div>
            <div className="mt-1 rounded-md shadow-sm">
              <InputField
                type={"password"}
                name={"password"}
                id="password"
                onBlur={formik.handleBlur}
                value={formik.values.password ?? ""}
                onChange={formik.handleChange}
              />
            </div>
            {
              <div className="mt-1 text-sm text-red-500">
                {" "}
                {formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : ""}
              </div>
            }
          </div>
          <button
            type={loading ? "button" : "submit"}
            className="flex items-center justify-center w-full px-3 py-2 mt-10 mb-6 font-medium transition rounded-lg bg-primary hover:bg-primary-800 gap-x-3 "
          >
            Se connecter{" "}
            {loading && (
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </button>
        </form>
      </div>
    </div>
  );
}
