import { useContext, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormLabel from "../../../UI/Form/FormLabel";
import InputField from "../../../UI/Form/InputField";
import ButtonLoading from "../../../UI/Button/ButtonLoading";
import Alert from "../../../UI/Alert/Alert";
import { NotifyContext } from "../../../context/notifyContext";
import { CommunityCategory } from "../../../Types/Community";
import CommunityAPI from "../../../services/api/CommunityService";

interface DeleteCategoryProps {
  onClose?: () => void;
  category?: CommunityCategory | null;
}

export default function DeleteCategory(props: DeleteCategoryProps) {
  const { category } = props;

  const notify = useContext(NotifyContext);

  const validators = {
    title: Yup.string().required("Veuillez entrer le titre"),
  };

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape(validators),
    initialValues: {
      title: "",
    },
    onSubmit: () => {
      setLoading(true);
      if (category)
        CommunityAPI.delete_category(category)
          .then((data) => {
            notify("Catégorie supprimée avec succès");
            setError("");
            formik.resetForm();
            if (props.onClose) {
              props.onClose();
            }
          })
          .catch((error) => {
            setError(error);
            notify(error, "error");
          })
          .finally(() => {
            setLoading(false);
          });
    },
  });

  return (
    <div>
      <form className="px-4 py-3" onSubmit={formik.handleSubmit}>
        {error && <Alert color="error">{error}</Alert>}

        <div className="mt-1">
          Les données relatives à cette catégorie seront perdues.
        </div>

        <div className={`mt-6 `}>
          <FormLabel htmlFor="title">
            Veuillez taper <strong>{category?.title}</strong> pour confirmer.
          </FormLabel>

          <div className="mt-1 rounded-md shadow-sm">
            <InputField
              type={"text"}
              name={"title"}
              id="title"
              placeholder={"ex. Web"}
              onBlur={formik.handleBlur}
              value={formik.values.title ?? ""}
              onChange={formik.handleChange}
            />
          </div>
        </div>

        <ButtonLoading
          type="submit"
          disabled={formik.values.title !== category?.title}
          loading={loading}
          label="Je comprends les conséquences, supprimez cette catégorie."
          className="mt-4"
        />
      </form>
    </div>
  );
}
