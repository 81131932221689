import { useContext, useState } from "react";
import { Collapse } from "react-collapse";
import { MdKeyboardArrowDown } from "react-icons/md";
import FormLabel from "../../../UI/Form/FormLabel";
import AutoCompleteCategory from "../../../components/Autocomplete/AutoCompleteCategory";
import { PostContext } from "../../../context/postContext";
import BlogPostStatusType from "../../../Types/Blogs/BlogPostStatus";

export default function PostDetails() {
  const [open, setOpen] = useState(true);

  const post_store = useContext(PostContext);

  return (
    <div className="w-full overflow-hidden bg-white rounded shadow">
      <div className="flex items-center justify-between gap-2 p-4 text-white bg-secondary ">
        <p className="text-lg font-medium">Détails du poste</p>
        <MdKeyboardArrowDown
          size={16}
          className={`transition-transform duration-300 cursor-pointer ${
            open ? " rotate-180 " : ""
          }`}
          onClick={() => setOpen(!open)}
        />
      </div>
      <div className="px-4 pt-0 ">
        <Collapse isOpened={open}>
          <div className={`mt-6 `}>
            <FormLabel htmlFor="status">Statut de l'article</FormLabel>

            <div className="mt-1 rounded-md shadow-sm">
              <select
                name="status"
                id="status"
                className="w-full form-select"
                value={post_store?.post?.status ? 1 : 0}
                onChange={(e) => {
                  if (post_store?.post) {
                    post_store?.updatePost({
                      ...post_store.post,
                      status: e.target.value,
                    });
                  }
                }}
              >
                {[
                  { label: "Publié", value: BlogPostStatusType.PUBLISHED },
                  { label: "Brouillon", value: BlogPostStatusType.DRAFT },
                ].map(({ label, value }) => {
                  return (
                    <option value={value} key={value}>
                      {label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className={`mt-6 pb-6 `}>
            <FormLabel htmlFor="category">Catégorie d'article</FormLabel>

            <div className="mt-1 rounded-md shadow-sm">
              <AutoCompleteCategory
                initial_value={post_store?.post?.category?.title}
                handleSelect={(category) => {
                  if (post_store?.updatePost && post_store.post) {
                    post_store.updatePost({
                      ...post_store.post,
                      category: category,
                      category_id: category.id,
                    });
                  }
                }}
              />
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
}
