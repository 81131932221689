import { useContext, useState } from "react";
import InputField from "../../../UI/Form/InputField";
import { Collapse } from "react-collapse";
import { MdKeyboardArrowDown } from "react-icons/md";
import { PostContext } from "../../../context/postContext";
import { Image } from "../../../Types";
import { getImageUrl } from "../../../utils/functions/images";

export default function PostImage() {
  const [open, setOpen] = useState(true);
  const [file, setFile] = useState<File | null>(null);

  const post_store = useContext(PostContext);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);

      if (post_store?.post)
        post_store.updatePost({
          ...post_store.post,
          poster_file: e.target.files[0],
        });
    }
  };

  return (
    <div className="w-full overflow-hidden bg-white rounded shadow">
      <div className="flex items-center justify-between gap-2 p-4 text-white bg-orange-600 ">
        <p className="text-lg font-medium">Image du poste</p>
        <MdKeyboardArrowDown
          size={16}
          className={`transition-transform duration-300 cursor-pointer ${
            open ? " rotate-180 " : ""
          }`}
          onClick={() => setOpen(!open)}
        />
      </div>
      <div className={` px-4 pt-4 ${open ? " pb-6 " : " pb-0 "}`}>
        <Collapse isOpened={open}>
          <label
            htmlFor="image"
            className="flex items-center justify-center px-4 pt-4 mt-1 border border-dashed cursor-pointer bloc h-80"
          >
            <InputField
              type={"file"}
              id="image"
              className="hidden"
              onChange={handleChange}
              accept="image/*"
            />
            {file ? (
              <img
                src={URL.createObjectURL(file)}
                alt="post cover"
                className="object-cover w-full h-full"
              />
            ) : post_store?.post?.poster ? (
              <img
                src={getImageUrl((post_store?.post?.poster as Image).url)}
                alt="post cover"
                className="object-cover w-full h-full"
              />
            ) : (
              "Selectionner une image"
            )}
          </label>
        </Collapse>
      </div>
    </div>
  );
}
