import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { SideBarContext } from "../../context/SideBarContext";
import clsx from "clsx";

export interface MenuItemProps {
  label: string;
  href: string;
  icon: JSX.Element;
  sub_items?: MenuItemProps[];
  className?: string;
}

export default function MenuItem(props: MenuItemProps) {
  const { label, href, icon } = props;

  const global_style = ` flex items-center flex-nowrap whitespace-nowrap px-3 h-14 text-sm gap-3 hover:bg-[#2a363b] hover:text-white ${props.className}`;

  const { reduceSideBar } = useContext(SideBarContext);

  return (
    <NavLink
      to={href}
      className={({ isActive, isPending }) =>
        isActive
          ? `text-white  ${global_style} `
          : isPending
          ? " "
          : `text-slate-400 ${global_style}`
      }
      key={label}
    >
      {icon}
      <span
        className={clsx(reduceSideBar ? "sm:hidden sm:group-hover:inline" : "")}
      >
        {label}
      </span>
    </NavLink>
  );
}
