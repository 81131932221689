import React, { useContext, useEffect } from "react";
import logo from "../../assets/logo.png";
import user_infos_bg from "../../assets/images/user_infos_bg.jpg";

import { BiCategory, BiUserCircle } from "react-icons/bi";
import { RiDashboardFill } from "react-icons/ri";
import {
  MdArticle,
  MdForum,
  MdOutlineClose,
  MdPeople,
  MdPerson,
  MdSettings,
  MdComment,
} from "react-icons/md";
import { BsFillQuestionSquareFill } from "react-icons/bs";
import { FaBloggerB, FaPersonBooth } from "react-icons/fa";
import Menu from "../../UI/Menu/Menu";
import { useNavigation } from "react-router";
import { SideBarContext } from "../../context/SideBarContext";
import { getUserInfo } from "../../utils/functions/auth";
import { User } from "../../Types";
import clsx from "clsx";

export default function SideBar() {
  const { openSideBar, setOpenSideBar, reduceSideBar } =
    useContext(SideBarContext);
  const navigation = useNavigation();

  useEffect(() => {
    if (navigation.state === "idle") {
      if (setOpenSideBar) setOpenSideBar(false);
    }
  }, [navigation.state, setOpenSideBar]);

  const user = getUserInfo();

  return (
    <div
      className={clsx(
        "w-[250px] fixed sm:static  top-0 h-screen left-0 transition-transform duration-500 group",
        openSideBar ? " translate-x-0" : " -translate-x-[10000px] z-50 ",
        "sm:translate-x-0",
        reduceSideBar
          ? "sm:!w-[60px] sm:overflow-hidden sm:hover:!w-[250px] sm:hover:overflow-auto"
          : ""
      )}
      style={{
        background: "linear-gradient(45deg,#353d47,#21292e)",
      }}
    >
      {/* LOGO && TITLE */}
      <div className="flex items-center h-[60px] bg-primary relative">
        <a
          href="/"
          className="h-[60px] w-[60px] flex items-center justify-center"
        >
          <img
            src={logo}
            alt="logo"
            className="max-w-[50%] max-h-[50%] object-cover"
          />
        </a>
        <p
          className={clsx(
            "text-lg text-white",
            reduceSideBar ? "sm:hidden sm:group-hover:inline" : ""
          )}
        >
          TANOU
        </p>
        <MdOutlineClose
          className="absolute block font-bold text-white -translate-y-1/2 cursor-pointer top-1/2 right-4 sm:hidden"
          size={24}
          onClick={() => {
            if (setOpenSideBar) {
              setOpenSideBar(false);
            }
          }}
        />
      </div>

      {/* INFOS USERS */}
      <div
        className="flex h-[60px] items-center py-2 gap-4 bg-secondary bg-no-repeat bg-cover relative before:absolute before:inset-0 before:bg-black before:z-10 before:opacity-80"
        style={{
          backgroundImage: `url('${user_infos_bg}')`,
        }}
      >
        <div className="z-20 flex items-center justify-center pl-2 text-white h-9 w-9 rounded-s-full">
          {/* <img
              src={""}
              alt="avatar"
              className="object-cover w-full h-full"
            /> */}
          <BiUserCircle size={36} className="" />
        </div>
        <p
          className={clsx(
            "z-20 overflow-hidden text-xl font-light text-white whitespace-nowrap text-ellipsis ",
            reduceSideBar ? "sm:hidden sm:group-hover:inline" : ""
          )}
        >{`${user?.data.first_name} ${user?.data.last_name}`}</p>
      </div>

      {/* MENU ITEMS */}
      <Menu
        items={[
          {
            label: "Tableau de bord",
            href: "/app/dashboard",
            icon: <RiDashboardFill size={18} />,
          },

          // {
          //   label: "Forum",
          //   href: "/app/forum",
          //   icon: <MdForum size={18} />,
          //   sub_items: [
          //     {
          //       label: "Messages",
          //       href: "/messages",
          //       icon: <BsFillQuestionSquareFill size={18} />,
          //     },
          //   ],
          // },
          {
            label: "Blog",
            href: "#",
            icon: <FaBloggerB size={18} />,
            sub_items: [
              {
                label: "Articles",
                href: "/app/blog/posts",
                icon: <MdArticle size={18} />,
              },
              {
                label: "Categories",
                href: "/app/blog/category",
                icon: <BiCategory size={18} />,
              },
              // {
              //   label: "Commentaires",
              //   href: "/app/blog/comments",
              //   icon: <MdComment size={18} />,
              // },
            ],
          },
          {
            label: "Communauté",
            href: "#",
            icon: <FaBloggerB size={18} />,
            sub_items: [
              {
                label: "Requêtes",
                href: "/app/community/requests",
                icon: <MdArticle size={18} />,
              },
              {
                label: "Categories",
                href: "/app/community/category",
                icon: <BiCategory size={18} />,
              },
              // {
              //   label: "Commentaires",
              //   href: "/app/community/comments",
              //   icon: <MdComment size={18} />,
              // },
            ],
          },
          {
            label: "Utilisateurs",
            href: "#",
            icon: <MdPeople size={18} />,
            sub_items: [
              {
                label: "Onboarding",
                href: "/app/users",
                icon: <FaPersonBooth size={18} />,
              },
              // {
              //   label: "Categories",
              //   href: "/app/blog/category",
              //   icon: <BiCategory size={18} />,
              // },
            ],
          },
          {
            label: "Paramètres",
            href: "/app/settings",
            icon: <MdSettings size={18} />,
          },
        ]}
      />
    </div>
  );
}
