import { PaginationType } from "../../Types/APITypes";
import { Category, Post } from "../../Types/Blogs";
import API from "./API";

class UserAPI extends API {
  static get_users = (pagination?: PaginationType) => {
    return this.getRequest(
      `manage/users?page=${pagination?.current_page || 1}&per_page=${
        pagination?.per_page || 10
      }${pagination?.search_word ? `&query=${pagination.search_word}` : ""} `,
      true
    );
  };

  static get_user = (id: number) => {
    return this.getRequest(`manage/users/${id}/show`, true);
  };

  static update_user_permissions = (id: number, permissions: string[]) => {
    return this.putRequest(
      `manage/users/${id}/update-permissions`,
      { permissions },
      true
    );
  };

  static confirm_user = (id: number) => {
    return this.patchRequest(`manage/onboarding/${id}/confirm`, {}, true);
  };

  static reject_user = (id: number) => {
    return this.patchRequest(`manage/onboarding/${id}/reject`, {}, true);
  };

  static add_category = (category: Category) => {
    return this.postRequest("manage/blog/categories/create", category, true);
  };

  static update_category = (category: Category) => {
    return this.putRequest(
      `manage/blog/categories/${category.id}/update`,
      category,
      true
    );
  };

  static delete_category = (category: Category) => {
    return this.deleteRequest(
      `manage/blog/categories/${category.id}/delete`,
      category,
      true
    );
  };

  static add_post = (post: Post) => {
    const formdata = new FormData();
    type postType = keyof Post;

    for (const key in post) {
      if (Object.prototype.hasOwnProperty.call(post, key)) {
        const element = post[key as postType];
        formdata.append(key, (element || "")?.toString());
      }
    }

    formdata.delete("poster");
    formdata.delete("poster_file");
    if (post.poster_file) formdata.append("poster", post.poster_file);

    return this.postFileRequest("manage/blog/posts/create", formdata, true);
  };

  static get_post = (id: number) => {
    return this.getRequest(`blog/posts/${id}/show`, true);
  };

  static update_post = (post: Post) => {
    const formdata = new FormData();
    type postType = keyof Post;

    for (const key in post) {
      if (Object.prototype.hasOwnProperty.call(post, key)) {
        const element = post[key as postType];
        formdata.append(key, (element || "")?.toString());
      }
    }

    formdata.delete("poster");
    formdata.delete("poster_file");
    if (post.poster_file) {
      formdata.append("poster", post.poster_file);
      return this.putFileRequest(
        `manage/blog/posts/${post.id}/update`,
        formdata,
        true
      );
    } else {
      const { poster, ...rest } = post;
      return this.putRequest(`manage/blog/posts/${post.id}/update`, rest, true);
    }
  };

  static get_posts = (pagination?: PaginationType) => {
    return this.getRequest(
      `blog/posts?page=${pagination?.current_page || 1}&per_page=${
        pagination?.per_page || 10
      }${pagination?.search_word ? `&query=${pagination.search_word}` : ""} `,
      true
    );
  };

  static update_post_status = (id: number, new_status: boolean) => {
    let link = "";

    switch (new_status) {
      case true:
        link = "publish";
        break;
      case false:
        link = "draft";
        break;

      default:
        break;
    }

    return this.putRequest(`manage/blog/posts/${id}/${link}`, {}, true);
  };
}

export default UserAPI;
