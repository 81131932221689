// @ts-ignore
import { useTable, Column, useSortBy, usePagination } from "react-table";
import Button from "../Button/Button";
import React, { useEffect, useState } from "react";
import { PaginationType } from "../../Types/APITypes";
import { useDebounce } from "../../utils/hooks/useDebounce";

interface TableProps {
  columns: Column[];
  data: any[];
  className?: string;
  fetchData: (pagination: PaginationType) => void;
  loading: boolean;
  pageCount: number;
  total: number;
}

const Table = (props: TableProps) => {
  const {
    columns,
    data,
    fetchData,
    loading,
    pageCount: controlledPageCount,
    total,
  } = props;

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: controlledPageCount,
      initialState: { pageIndex: 0, pageSize: 20 },
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  const [search_word, setSearchWord] = useState("");
  const search_word_debounce: string = useDebounce(search_word);

  useEffect(() => {
    fetchData({
      current_page: pageIndex,
      per_page: pageSize,
      search_word: search_word_debounce,
    });
  }, [fetchData, pageIndex, pageSize, search_word_debounce]);

  return (total > 0 && !loading) || data.length > 0 ? (
    <div
      className={`bg-white shadow p-4 py-5 rounded ${props.className} overflow-hidden flex flex-col  `}
    >
      <div className="flex items-center justify-between mb-8 gap-x-2">
        <div className="flex items-center gap-1">
          <p>Afficher</p>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="form-select"
            name=""
            id=""
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entrees</p>
        </div>

        <div className="flex items-center gap-2 ">
          <p>Rechercher</p>
          <input
            value={search_word}
            onChange={(e) => setSearchWord(e.target.value)}
            type="search"
            className="flex-1 w-64 form-input"
            name="search_word"
            placeholder="recherche"
          />
        </div>
      </div>
      <div className={` flex-1 overflow-auto`}>
        <table {...getTableProps()} className="w-full ">
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup: any) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()} className="pb-4">
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column: any) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className="px-3 py-4 text-left bg-gray-100 border-b border-black/20 "
                      >
                        {
                          // Render the header
                          column.render("Header")
                        }
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()} className="py-4">
            {
              // Loop over the table rows
              page.map((row: any) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()} className="hover:bg-gray-50 ">
                    {
                      // Loop over the rows cells
                      row.cells.map((cell: any) => {
                        // Apply the cell props
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="px-2 py-4 border-b border-black/20 "
                          >
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
            <tr>
              {loading ? (
                <td colSpan={100} className="py-4 text-center">
                  Loading...
                </td>
              ) : (
                <td colSpan={100} className="py-4">
                  Affichage de{" "}
                  {pageIndex === 0
                    ? total === 0
                      ? 0
                      : 1
                    : pageSize * pageIndex}{" "}
                  à{" "}
                  {(pageIndex === 0 ? 1 : pageSize * pageIndex) +
                    page.length -
                    1}{" "}
                  sur {total} entrées
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex items-center justify-between gap-2 mt-6 ">
        <div className="flex items-center gap-2">
          <Button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className="text-xs border rounded-sm bg-slate-100 hover:bg-slate-200 "
          >
            {"<<"}
          </Button>{" "}
          <Button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="text-xs border rounded-sm bg-slate-100 hover:bg-slate-200 "
          >
            {"<"}
          </Button>{" "}
          <Button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="text-xs border rounded-sm bg-slate-100 hover:bg-slate-200 "
          >
            {">"}
          </Button>{" "}
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className="text-xs border rounded-sm bg-slate-100 hover:bg-slate-200 "
          >
            {">>"}
          </Button>{" "}
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} sur {pageOptions.length}
            </strong>{" "}
          </span>
          <span>
            | Aller a la page:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              className="px-2 py-1 border rounded w-28 "
              max={pageOptions.length}
              min={1}
            />
          </span>
        </div>
      </div>
    </div>
  ) : null;
};

export default React.memo(Table);
