import { Image } from ".";

export interface Category {
  id?: string | number;
  title: string;
  description?: string;
  slug?: string;
  poster?: any;
  status?: boolean;
  created_at?: string;
  updated_at?: string;
}

export interface PostTag {
  id?: string | number;
  title: string;
  created_at?: string;
  updated_at?: string;
}

export interface PostComment {
  id?: string | number;
  content?: string;
  status?: string;
  author_user_id?: string | number;
  blog_post_id?: string | number;
  created_at: string;
  updated_at: string;
  author: {
    first_name: string;
    last_name: string;
    avatar: string
  }
}

export enum BlogPostCommentStatusType {
  DRAFT = '0',
  PENDING = '1',
  PUBLISHED = '2',
  DELETED = '3',
}


export interface Post {
  id?: string | number;
  title: string;
  description?: string;
  status?: string;
  poster?: string | Image;
  poster_file?: File | null;
  content?: string;
  category_id?: string | number;
  category?: Category;
  tags?: any[];
  is_published?: boolean;
  author?: any;

  seo_title?: string;
  excerpt?: string;
  meta_description?: string;
  meta_keywords?: string;
  featured?: string;
  slug?: string;
  created_at?: string;
  updated_at?: string;
  author_id?: string | number;
}
