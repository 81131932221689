import React, { ReactNode, useState } from "react";

export interface SideBarValueType {
  openSideBar?: boolean;
  setOpenSideBar?: React.Dispatch<React.SetStateAction<boolean>>;
  reduceSideBar?: boolean;
  setReduceSideBar?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SideBarContext = React.createContext<SideBarValueType>({
  openSideBar: false,
});

export const SideBarProvider = ({ children }: { children: ReactNode }) => {
  const [openSideBar, setOpenSideBar] = useState(false);
  const [reduceSideBar, setReduceSideBar] = useState(false);

  return (
    <SideBarContext.Provider
      value={{ openSideBar, setOpenSideBar, reduceSideBar, setReduceSideBar }}
    >
      {children}
    </SideBarContext.Provider>
  );
};

export default SideBarProvider;
