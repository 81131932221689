import clsx from "clsx";
import React from "react";

interface ModalProps {
  title?: string;
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
}

export default function Modal(props: ModalProps) {
  const { title, children, onClose, open } = props;

  return (
    <div
      aria-hidden="true"
      className={clsx(
        "fixed top-0 left-0 right-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full bg-black/30 flex-col items-center transition-all duration-500 flex",
        open ? " opacity-100 visible " : " opacity-0 invisible "
      )}
    >
      <div className="relative w-full max-w-2xl max-h-full mt-6">
        <div className="relative bg-white rounded-lg shadow ">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
            onClick={() => onClose()}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="px-6 py-6 lg:px-8">
            {title ? (
              <h3 className="mb-4 text-xl font-medium text-gray-900 ">
                {title}
              </h3>
            ) : (
              ""
            )}

            <div>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
