import React, { useContext } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import Breadcrumb from "../../UI/Breadcrumb/Breadcrumb";
import UserDropDownMenu from "./UserDropDownMenu";
import { BreadcrumbsContext } from "../../context/BreadcrumbsContext";
import { SideBarContext } from "../../context/SideBarContext";
import { BiLeftArrowAlt } from "react-icons/bi";
import clsx from "clsx";

export default function AppBar() {
  const breadcrumbsContextValue = useContext(BreadcrumbsContext);

  const { openSideBar, setOpenSideBar, reduceSideBar, setReduceSideBar } =
    useContext(SideBarContext);

  return (
    <div className="flex items-center justify-between gap-4 border-b border-slate-200 pb-2 h-[60px] pt-2">
      <div className="flex items-center gap-4 px-4">
        <GiHamburgerMenu
          onClick={() => {
            if (setOpenSideBar) {
              setOpenSideBar(!openSideBar);
            }
          }}
          size={20}
          className="block cursor-pointer sm:hidden"
        />
        <BiLeftArrowAlt
          className={clsx(
            "cursor-pointer transition duration-300 hidden sm:block",
            reduceSideBar ? "transform rotate-180" : ""
          )}
          onClick={() => {
            if (setReduceSideBar) {
              setReduceSideBar(!reduceSideBar);
            }
          }}
        />

        {/* bradcumbs */}
        {breadcrumbsContextValue?.breadcrumbs_items &&
        breadcrumbsContextValue?.breadcrumbs_items?.length > 0 ? (
          <Breadcrumb items={breadcrumbsContextValue.breadcrumbs_items} />
        ) : null}
      </div>
      <UserDropDownMenu />
    </div>
  );
}
