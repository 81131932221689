import React from "react";
import MenuItem, { MenuItemProps } from "./MenuItem";
import NestedItem from "./NestedItem";

export interface MenuProps {
  items: MenuItemProps[];
}

export default function Menu(props: MenuProps) {
  const { items } = props;
  return (
    <>
      {items.map((item: MenuItemProps) => {
        const { label, sub_items = [] } = item;
        return sub_items?.length > 0 ? (
          <NestedItem item={item} key={label} />
        ) : (
          <MenuItem {...item} key={label} />
        );
      })}
    </>
  );
}
