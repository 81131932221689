import React, { useEffect, useRef, useState } from "react";

import default_user_avatar from "../../assets/images/avatar.png";
import { HiOutlineLogout } from "react-icons/hi";
import { FaUser } from "react-icons/fa";
import { MdSettings } from "react-icons/md";
import { getUserInfo, logOut } from "../../utils/functions/auth";
import Button from "../../UI/Button/Button";

export default function UserDropDownMenu() {
  const user = getUserInfo();

  const [open, setOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (!ref.current?.contains(e.target as HTMLElement) && open) {
        handleClose();
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [open]);

  return (
    <div className="relative px-4 py-1" ref={ref}>
      <button
        type="button"
        className="w-8 h-8 rounded-full"
        onClick={() => setOpen(true)}
      >
        <img
          src={default_user_avatar}
          alt="user profile"
          className="object-cover w-full h-full"
        />
      </button>
      <div
        className={`${
          open ? "visible opacity-100" : "invisible opacity-0"
        } transition-all duration-200 absolute top-[105%] right-4 bg-white shadow-lg py-4 rounded-lg`}
      >
        <div className="flex items-center gap-4 px-4 pb-4 border-b border-slate-200">
          <div className="rounded-full h-14 w-14">
            <img
              src={default_user_avatar}
              alt="user profile"
              className="object-cover w-full h-full"
            />
          </div>
          <div className="text-slate-400">
            <h4 className="overflow-hidden text-sm whitespace-nowrap text-ellipsis">{`${user?.data?.first_name} ${user?.data?.last_name}`}</h4>
            <p className="overflow-hidden text-xs whitespace-nowrap text-ellipsis">
              {user?.data?.email}
            </p>
          </div>
        </div>

        <ul>
          {[
            {
              label: "profile",
              icon: <FaUser />,
            },
            {
              label: "Paramètres",
              icon: <MdSettings />,
            },
          ]?.map((item, index) => (
            <li
              key={item.label}
              onClick={() => {
                handleClose();
              }}
              className="flex items-center gap-3 px-6 py-3 text-sm cursor-pointer hover:bg-black/5 opacity-80 hover:opacity-100"
            >
              {item.icon}
              {item.label}
            </li>
          ))}
        </ul>

        <Button
          className="flex items-center justify-center w-[90%] mx-auto gap-4 px-3 py-2 mt-6 font-semibold text-white rounded-md bg-error"
          onClick={() => logOut()}
          color="error"
        >
          <HiOutlineLogout /> Deconnexion
        </Button>
      </div>
    </div>
  );
}
