import { useContext, useEffect, useRef, useState } from "react";
import { FaBloggerB } from "react-icons/fa";
import useBreadcrumbs from "../../../utils/hooks/useBreadcrumbs";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputField from "../../../UI/Form/InputField";
import PostDetails from "./PostDetails";
import PostImage from "./PostImage";
import PostSeoContent from "./PostSeoContent";
import PostContent from "./PostContent";
import ButtonLoading from "../../../UI/Button/ButtonLoading";
import { PostContext } from "../../../context/postContext";
import BlogAPI from "../../../services/api/BlogService";
import { NotifyContext } from "../../../context/notifyContext";
import clsx from "clsx";
import { useNavigate, useParams } from "react-router-dom";
import { Post } from "../../../Types/Blogs";
import { BreadcrumbsItemType } from "../../../context/BreadcrumbsContext";
import { Editor } from "@tinymce/tinymce-react";
import BlogPostStatusType from "../../../Types/Blogs/BlogPostStatus";

// import  from "react-autocomplete"

export default function AddPost() {
  const { id } = useParams();
  const editorRef = useRef<Editor | null>(null);

  const breadcrumbs: BreadcrumbsItemType[] = [
    {
      label: "Blogs",
      icon: <FaBloggerB />,
      is_link: true,
      href: "/app/blog",
    },
    {
      label: "Articles",
      is_link: true,
      href: "/app/blog/posts",
    },
  ];

  if (id) {
    breadcrumbs.push(
      {
        label: id,
        is_link: false,
      },
      {
        label: "Edition",
        is_link: false,
      }
    );
  } else {
    breadcrumbs.push({
      label: "Nouveau",
      is_link: false,
    });
  }

  useBreadcrumbs(breadcrumbs);

  const notify = useContext(NotifyContext);

  const validators = {
    title: Yup.string().required("Veuillez donner un titre a votre article"),
    description: Yup.string().required(
      "Veuillez donner une description a votre article"
    ),
    // category_id: Yup.string().required("Veuillez selectionner une categorie"),
  };

  const post_store = useContext(PostContext);

  const [post, setPost] = useState<Post | null>(null);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape(validators),
    initialValues: post
      ? { ...post, category_id: post.category?.id }
      : {
          title: "",
          description: "",
          category_id: "",
          status: BlogPostStatusType.DRAFT,
          poster_file: null,
          content: "",
        },
    onSubmit: (values) => {
      if (editorRef.current)
        values.content = (editorRef.current as any).getContent();

      if (!values.category_id) {
        notify("Veuillez selectionner une categorie", "error");
      } else if (!values.content) {
        notify("Contenue de l'article vide", "error");
      } else {
        setLoading(true);
        if (id) {
          BlogAPI.update_post({ ...post, ...values, tags: [] })
            .then((data) => {
              notify("Article enregistrée avec succès");
              setError("");
              const value = data as unknown as {
                data: {
                  post: Post;
                };
              };
              setPost(value.data.post);
            })
            .catch((error) => {
              setError(error);
              notify(error, "error");
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          BlogAPI.add_post({ ...values })
            .then((data) => {
              notify("Article crée avec succès");
              setError("");

              const value = data as unknown as {
                data: {
                  post: Post;
                };
              };
              if (value) {
                navigate(`/app/blog/posts/${value.data.post.id}/edit`);
              }
            })
            .catch((error) => {
              setError(error);
              notify(error, "error");
            })
            .finally(() => {
              setLoading(false);
            });
        }
      }
    },
  });

  useEffect(() => {
    if (id) {
      BlogAPI.get_post(parseInt(id))
        .then((data) => {
          const value = data as unknown as {
            data: {
              post: Post;
            };
          };
          if (value) {
            setPost(value.data.post);
          }
        })
        .catch((error) => {
          setError(error);
          notify(error, "error");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      post_store?.updatePost({
        title: "",
        id: 0,
        status: BlogPostStatusType.DRAFT,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    formik.setFieldValue("category_id", post_store?.post?.category_id);
    formik.setFieldValue("status", post_store?.post?.status);
    formik.setFieldValue("poster_file", post_store?.post?.poster_file);
    formik.setFieldValue("content", post_store?.post?.content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post_store?.post]);

  useEffect(() => {
    if (post) {
      post_store?.updatePost({ ...post, category_id: post.category?.id || "" });
      // (editorRef.current as any).currentContent = post.content;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post]);

  return (
    <div className="w-full h-full overflow-auto">
      <div
        className={clsx(
          "px-3 py-2 mt-2 mb-4 text-red-500 bg-red-50 opacity-0 invisible transition duration-300 hidden",
          error ? "opacity-100 visible block" : ""
        )}
      >
        {error ? error : ""}
      </div>

      <div className="flex flex-wrap items-start h-full gap-8 ">
        <div className="flex-1 space-y-12 ">
          <div className="p-4 bg-white rounded shadow ">
            <h3 className="text-lg font-semibold">Titre du poste</h3>
            <p className="text-sm opacity-80">Le titre de votre article</p>

            <div className="mt-4 rounded-md shadow-sm">
              <InputField
                type={"text"}
                name={"title"}
                id="title"
                onBlur={formik.handleBlur}
                value={formik.values.title ?? ""}
                onChange={formik.handleChange}
              />
            </div>
            {
              <div className="mt-1 text-sm text-red-500 ">
                {" "}
                {formik.touched.title && formik.errors.title
                  ? formik.errors.title
                  : ""}
              </div>
            }

            <div className="mt-8">
              <label className="text-lg font-semibold ">
                Description du poste
              </label>
              <p className="text-sm opacity-80">
                Donnez un breve resume de votre article
              </p>

              <div className="w-full mt-4 rounded-md shadow-sm">
                <textarea
                  name={"description"}
                  id="description"
                  onBlur={formik.handleBlur}
                  value={formik.values.description ?? ""}
                  onChange={formik.handleChange}
                  rows={3}
                  className="w-full form-input"
                ></textarea>
              </div>

              <div className="mt-1 text-sm text-red-500 ">
                {" "}
                {formik.touched.description && formik.errors.description
                  ? formik.errors.description
                  : ""}
              </div>
            </div>
          </div>
          <PostContent ref={editorRef} />
        </div>
        <div className="space-y-12 w-96 ">
          <PostDetails />
          <PostImage />
          {/* <PostSeoContent /> */}
        </div>
        <div className="flex items-center justify-end w-full">
          <ButtonLoading
            onClick={() => formik.handleSubmit()}
            loading={loading}
            label={id ? "Enregistrer article" : "Creer un nouvel article"}
          />
        </div>
      </div>
    </div>
  );
}
