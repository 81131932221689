import { useContext, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormLabel from "../../../UI/Form/FormLabel";
import InputField from "../../../UI/Form/InputField";
import ButtonLoading from "../../../UI/Button/ButtonLoading";
import BlogAPI from "../../../services/api/BlogService";
import Alert from "../../../UI/Alert/Alert";
import { NotifyContext } from "../../../context/notifyContext";
import { PostComment } from "../../../Types/Blogs";

interface DisableCommentProps {
  onClose?: () => void;
  comment?: PostComment | null;
}

export default function DisableComment(props: DisableCommentProps) {
  const { comment } = props;

  const notify = useContext(NotifyContext);

  const validators = {
    title: Yup.string(),
  };

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape(validators),
    initialValues: {
      title: "",
    },
    onSubmit: () => {
      setLoading(true);
      if (comment)
        BlogAPI.disable_post_comment(comment)
          .then((data) => {
            notify("Commentaire désactivé avec succès");
            setError("");
            formik.resetForm();
            if (props.onClose) {
              props.onClose();
            }
          })
          .catch((error) => {
            setError(error);
            notify(error, "error");
          })
          .finally(() => {
            setLoading(false);
          });
    },
  });

  return (
    <div>
      <form className="px-4 py-3" onSubmit={formik.handleSubmit}>
        <div className="mt-1">
          Le commentaire ne sera plus affiché pour tous les visiteurs du blog.
        </div>

        <div className={`mt-6 text-sm`}>
          Vous pourrez réactiver à tout moment.
        </div>

        <ButtonLoading
          type="submit"
          loading={loading}
          label="Je comprends, désactiver ce commentaire."
          className="mt-4"
        />
      </form>
    </div>
  );
}
