import React, { LabelHTMLAttributes } from "react";

interface FormLabelProps extends LabelHTMLAttributes<HTMLLabelElement> {}
export default function FormLabel(props: FormLabelProps) {
  return (
    <label
      className={`block text-sm font-medium leading-5 text-gray-700  ${props.className}`}
      {...props}
    >
      {" "}
      {props.children}{" "}
    </label>
  );
}
