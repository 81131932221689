import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { SlOptions } from "react-icons/sl";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { MdPreview } from "react-icons/md";
import Modal from "../../../UI/Modal/Modal";
import HandleStatusUser from "./HandleStatusUser";
import { User, UserStatus } from "../../../Types/Users";

type MenuUserProps = {
  user: User;
  fetchData: () => void;
};

export default function MenuUser({ user, fetchData }: MenuUserProps) {
  const [modal_status, setModalStatus] = useState<{
    open: boolean;
    user?: User | null;
  }>({ open: false, user: null });
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Modal
        open={modal_status.open}
        onClose={() => {
          setModalStatus({ open: false });
        }}
      >
        <HandleStatusUser
          onClose={() => {
            fetchData();
            setModalStatus({ open: false });
          }}
          initialState={modal_status.user}
        />
      </Modal>

      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-900">
          <SlOptions
            className="w-4 h-4 -mr-1 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {/* <Menu.Item>
              {({ active }: { active: boolean }) => (
                <Link
                  to={`/app/blog/users/${user.id}/edit`}
                  className={clsx(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    " px-4 py-2 text-sm flex items-center gap-2 w-full"
                  )}
                >
                  <MdEdit />
                  Editer
                </Link>
              )}
            </Menu.Item> */}
            <Menu.Item>
              {({ active }: { active: boolean }) => (
                <Link
                  to={`/app/users/${user.id}/view`}
                  className={clsx(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    " px-4 py-2 text-sm flex items-center gap-2 w-full"
                  )}
                >
                  <MdPreview />
                  {user.data.user_status === UserStatus.PENDING ||
                  user.data.user_status === UserStatus.CONFIRMED
                    ? "Gérer la demande"
                    : "Afficher"}
                </Link>
              )}
            </Menu.Item>

            {/* <Menu.Item>
              {({ active }: { active: boolean }) => (
                <button
                  type="button"
                  className={clsx(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    " px-4 py-2 text-sm flex items-center gap-2 w-full"
                  )}
                  onClick={() => setModalStatus({ open: true, user })}
                >
                  {!user.data?.user_status ? (
                    <>
                      <MdPublish />
                      Publier
                    </>
                  ) : (
                    <>
                      {" "}
                      <MdUnpublished />
                      Dépublier
                    </>
                  )}
                </button>
              )}
            </Menu.Item> */}
          </div>
          {/* <div className="py-1">
            <Menu.Item>
              {({ active }: { active: boolean }) => (
                <button
                  type="button"
                  className={clsx(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    " px-4 py-2 text-sm flex items-center gap-2 w-full text-red-800"
                  )}
                >
                  <MdDelete />
                  Supprimer
                </button>
              )}
            </Menu.Item>
          </div> */}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
