import { Image } from ".";
import CommunityRequestStatusType from "./Community/CommunityRequestStatus";



export enum CommunityRequestCommentStatusType {
  DRAFT = '0',
  PENDING = '1',
  PUBLISHED = '2',
  DELETED = '3',
}

export interface CommunityCategory {
  id?: string | number;
  slug?: string;
  status?: boolean;
  title: string;
  poster?: string;
  description?: string;
  created_at?: string;
  updated_at?: string;
}

export interface CommunityTags {
  id?: string | number;
  title: string;
  created_at?: string;
  updated_at?: string;
}

export interface CommunityComments {
  id?: string | number;
  content?: string;
  status?: string;
  author_user_id?: string | number;
  community_request_id?: string | number;
  created_at: string;
  updated_at: string;
  author: {
    first_name: string;
    last_name: string;
    avatar: string
  }
}

export interface CommunityRequest {
  poster_file?: any;
  id: string | number;
  slug: string;
  title: string;
  description: string;
  poster?: Image;
  content: string;
  status: CommunityRequestStatusType;
  author_user_id: number;
  community_category_id: number;
  created_at: string;
  updated_at: string;
  tags: CommunityTags[];
  is_published?: boolean;

  author: {
    first_name: string;
    last_name?: string;
    poster?: Image;
    avatar?: string
  }
  data: {
    request: CommunityRequest;
  }

  seo_title: string;
  excerpt: string;
  meta_description: string;
  meta_keywords: string;
  featured: string;
  author_id: string | number;
  category_id: string | number;
  category?: CommunityCategory;
}
