import FormLabel from "../../../UI/Form/FormLabel";
import InputField from "../../../UI/Form/InputField";
import Button from "../../../UI/Button/Button";
import { CommunityCategory } from "../../../Types/Community";

interface ShowCategoryProps {
  onClose?: () => void;
  category?: CommunityCategory | null;
}

export default function ShowCategory(props: ShowCategoryProps) {
  const { category } = props;

  return (
    <div>
      <div className="px-4 py-3">
        <div className={`mt-6 `}>
          <FormLabel htmlFor="title">Titre</FormLabel>
          <div className="mt-1 rounded-md shadow-sm">
            <InputField
              type={"text"}
              name={"title"}
              id={"title"}
              defaultValue={category?.title}
              readOnly
            />
          </div>
        </div>

        <div className={`mt-6 `}>
          <FormLabel htmlFor="description">Description</FormLabel>
          <div className="mt-1 rounded-md shadow-sm">
            <textarea
              name={"description"}
              id="description"
              defaultValue={category?.description}
              readOnly
              cols={30}
              rows={5}
              className="w-full form-input"
            ></textarea>
          </div>
        </div>

        <Button
          type="button"
          color="primary"
          onClick={() => {
            if (props.onClose) {
              props.onClose();
            }
          }}
        >
          Fermer
        </Button>
      </div>
    </div>
  );
}
