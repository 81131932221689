import { useContext, useState } from "react";
import { FaBloggerB } from "react-icons/fa";
import useBreadcrumbs from "../../../utils/hooks/useBreadcrumbs";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormLabel from "../../../UI/Form/FormLabel";
import InputField from "../../../UI/Form/InputField";
import ButtonLoading from "../../../UI/Button/ButtonLoading";
import Alert from "../../../UI/Alert/Alert";
import { NotifyContext } from "../../../context/notifyContext";
import CommunityAPI from "../../../services/api/CommunityService";
import { CommunityCategory } from "../../../Types/Community";

interface AAddOrUpdateCategoryProps {
  onClose?: () => void;
  initialState?: CommunityCategory | null;
}

export default function AddOrUpdateCategory(props: AAddOrUpdateCategoryProps) {
  useBreadcrumbs([
    {
      label: "Communauté",
      icon: <FaBloggerB />,
      is_link: true,
      href: "/app/community",
    },
    {
      label: "Categories",
      is_link: true,
      href: "/app/community/category",
    },
    {
      label: "Nouveau",
      is_link: false,
    },
  ]);

  const { initialState } = props;

  const notify = useContext(NotifyContext);

  const validators = {
    title: Yup.string().required("Veuillez entrer le titre"),
  };

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape(validators),
    initialValues: {
      title: initialState?.title || "",
      description: initialState?.description || "",
    },
    onSubmit: (values) => {
      setLoading(true);

      if (initialState) {
        CommunityAPI.update_category({ ...initialState, ...values })
          .then((data) => {
            notify("Catégorie modifiée avec succès");
            setError("");
            formik.resetForm();
            if (props.onClose) {
              props.onClose();
            }
          })
          .catch((error) => {
            setError(error);
            notify(error, "error");
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        CommunityAPI.add_category({ ...values })
          .then((data) => {
            notify("Catégorie crée avec succès");
            setError("");
            formik.resetForm();
            if (props.onClose) {
              props.onClose();
            }
          })
          .catch((error) => {
            setError(error);
            notify(error, "error");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  return (
    <div>
      <form className="px-4 py-3" onSubmit={formik.handleSubmit}>
        {error && <Alert color="error">{error}</Alert>}
        <div
          className={`mt-6 ${
            formik.touched.title && formik.errors.title && " text-red-500 "
          } `}
        >
          <FormLabel htmlFor="title">Nom</FormLabel>

          <div className="mt-1 rounded-md shadow-sm">
            <InputField
              type={"text"}
              name={"title"}
              id="title"
              placeholder={"ex. Web"}
              onBlur={formik.handleBlur}
              value={formik.values.title ?? ""}
              onChange={formik.handleChange}
            />
          </div>
          {
            <div className="mt-1 text-sm text-red-500">
              {" "}
              {formik.touched.title && formik.errors.title
                ? formik.errors.title
                : ""}
            </div>
          }
        </div>

        <div
          className={`mt-6 ${
            formik.touched.description &&
            formik.errors.description &&
            " text-red-500 "
          } `}
        >
          <FormLabel htmlFor="description">Description</FormLabel>

          <div className="mt-1 rounded-md shadow-sm">
            <textarea
              name={"description"}
              id="description"
              onBlur={formik.handleBlur}
              value={formik.values.description ?? ""}
              onChange={formik.handleChange}
              cols={30}
              rows={5}
              className="w-full form-input"
            ></textarea>
          </div>
          {
            <div className="mt-1 text-sm text-red-500">
              {" "}
              {formik.touched.description && formik.errors.description
                ? formik.errors.description
                : ""}
            </div>
          }
        </div>

        <ButtonLoading
          type="submit"
          loading={loading}
          label={initialState ? "Modifier" : "Enregistrer"}
        />
      </form>
    </div>
  );
}
