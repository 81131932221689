import { useContext, useState } from "react";
import { useFormik } from "formik";
import FormLabel from "../../../UI/Form/FormLabel";
import ButtonLoading from "../../../UI/Button/ButtonLoading";
import Alert from "../../../UI/Alert/Alert";
import { NotifyContext } from "../../../context/notifyContext";
import UserAPI from "../../../services/api/UserService";
import { Student } from "../../../Types/Users";

interface AcceptStudentProps {
  onClose?: () => void;
  student?: Student | null;
}

export default function AcceptStudent(props: AcceptStudentProps) {
  const { student } = props;

  const notify = useContext(NotifyContext);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
    },
    onSubmit: () => {
      setLoading(true);
      if (student)
        UserAPI.confirm_user(student.user_id)
          .then((data) => {
            notify("Vous avez confirmé l'étudiant");
            setError("");
            if (props.onClose) {
              props.onClose();
            }
          })
          .catch((error) => {
            setError(error);
            notify(error, "error");
          })
          .finally(() => {
            setLoading(false);
          });
    },
  });

  return (
    <div>
      <form className="px-4 py-3" onSubmit={formik.handleSubmit}>
        {error && <Alert color="error">{error}</Alert>}

        <div className={`mt-6 `}>
          <FormLabel htmlFor="title">
            Voulez-vous confirmer{" "}
            <strong>
              {student?.first_name} {student?.last_name}
            </strong>{" "}
            comme étudiant ?
          </FormLabel>
        </div>

        <ButtonLoading
          type="submit"
          loading={loading}
          label="Je confirme !"
          className="mt-4"
        />
      </form>
    </div>
  );
}
