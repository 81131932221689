import API from "./API";

class AuthAPI extends API {
  static login = (info: object) => {
    return this.postRequest("auth/login", info, false);
  };

  static send_mail_password_reset = (email: string) => {
    return this.postRequest("auth/password/email", { email }, false);
  };

  static verify_token = (token: string) => {
    return this.getRequest(`auth/password/reset/${token}`, false);
  };

  static reset_password = (data: {
    token: string;
    password: string;
    password_confirmation: string;
  }) => {
    return this.postRequest("auth/password/email", { ...data }, false);
  };
}

export default AuthAPI;
