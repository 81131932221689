import SecureLS from "secure-ls";
import UserModel from "../../Models/User";
import { User } from "../../Types";
const ls = new SecureLS({ encodingType: "aes" });

export const setUserInfo = (user: User | null) => {
  ls.set("user", user);
};

// export const getUserInfo = (): UserModel => {
//   return ls.get("user") ? ls.get("user") || {} : "";
// };

export const getUserInfo = (): UserModel | null => {
  const user = ls.get("user") ? ls.get("user") || {} : "";

  if (user) {
    return Object.assign(new UserModel(), user);
  }

  return null;
};

export const setToken = (token: String | null) => {
  ls.set("token", token);
};

export const getToken = () => {
  return ls.get("token") ? ls.get("token") || null : null;
};

export const isAuth = () => {
  return getToken() ? true : false;
};

export const logOut = () => {
  if (typeof window !== "undefined") {
    setToken(null);
    setUserInfo(null);
    window.location.reload();
  }
};
