import SideBar from "./SideBar";
import { Outlet, useNavigation } from "react-router";
import AppBar from "./AppBar";

export interface AppProps {}

export default function LayoutApp(props: AppProps) {
  const navigation = useNavigation();

  return (
    <div className="flex h-screen">
      <SideBar />

      <div
        className={`flex-1 flex flex-col overflow-hidden  h-full ${
          navigation.state === "loading" ? "loading" : ""
        }`}
      >
        <AppBar />

        <div className="flex flex-col flex-1 px-4 py-4 overflow-scroll ">
          {navigation.state === "loading" ? "...loading" : <Outlet />}
        </div>
      </div>
    </div>
  );
}
