import { Editor } from "@tinymce/tinymce-react";
import { forwardRef, useContext, useEffect, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { EDITOR_KEY } from "../../../config/app";
import { PostContext } from "../../../context/postContext";


const PostContent = forwardRef<Editor | null, any>((_, ref) => {
  const [open, setOpen] = useState(false);
  const [initial_value, setInitialValue] = useState(
    ""
  );

  const post_store = useContext(PostContext);

  useEffect(() => {
    setInitialValue(post_store?.post?.content || "");
  }, [post_store?.post?.content]);

  useEffect(() => {});

  return (
    <div className="w-full overflow-hidden bg-white rounded shadow">
      <div className="flex items-center justify-between gap-2 p-4 ">
        <p className="text-lg font-medium">Contenu de l'article</p>
        <MdKeyboardArrowDown
          size={16}
          className={`transition-transform duration-300 cursor-pointer ${
            open ? " rotate-180 " : ""
          }`}
          onClick={() => setOpen(!open)}
        />
      </div>
      <div className={` px-4 pt-4 ${open ? " pb-6 " : " pb-0 "}`}>
        <div className="pb-6">
          <Editor
            onInit={(evt, editor) => {
              if (ref) {
                (ref as any).current = editor;

                editor.ui.registry.addButton("customInsertButton", {
                  text: "My Button",
                  onAction: function (_) {
                    editor.insertContent(
                      "&nbsp;<strong>It's my button!</strong>&nbsp;"
                    );
                  },
                });
              }
            }}
            apiKey={EDITOR_KEY}
            initialValue={initial_value || ""}
            init={{
              branding: false,
              height: 400,
              menubar: true,
              plugins:
                " preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern fullscreen code eqneditor",
              toolbar:
                "eqneditor formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat | code ",
              image_advtab: true,
              language: "fr_FR",
            }}
            // @ts-ignore
            ref={ref}
            // onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
});

export default PostContent;
