import React, { ReactNode, useState } from "react";
import { Post } from "../Types/Blogs";

export const PostContext = React.createContext<{
  post: Post | null | undefined;
  updatePost: (post: Post | null | undefined) => void;
} | null>(null);

export const PostProvider = ({ children }: { children: ReactNode }) => {
  const [post, setPost] = useState<Post | null | undefined>(null);
  const updatePost = (post: Post | null | undefined) => {
    setPost(post);
  };
  return (
    <PostContext.Provider value={{ post, updatePost }}>
      {children}
    </PostContext.Provider>
  );
};
