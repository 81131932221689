import { useContext, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import MenuItem, { MenuItemProps } from "./MenuItem";
import { Collapse } from "react-collapse";
import { SideBarContext } from "../../context/SideBarContext";
import clsx from "clsx";

const NestedItem = (props: { item: MenuItemProps }) => {
  const { label, icon, sub_items = [] } = props.item;

  const [open, setOpen] = useState(false);

  const { reduceSideBar } = useContext(SideBarContext);

  return (
    <div key={label}>
      <div
        className={`text-slate-400 flex items-center justify-between px-3 h-14 text-sm hover:bg-[#2a363b]  cursor-pointer ${
          open ? "hover:text-primary " : "hover:text-white"
        }`}
        onClick={() => setOpen(!open)}
      >
        <div className="flex items-center gap-3">
          {icon}
          <span
            className={clsx(
              reduceSideBar ? "sm:hidden sm:group-hover:inline" : ""
            )}
          >
            {label}
          </span>
        </div>
        <MdKeyboardArrowDown
          size={16}
          className={`transition-transform duration-300 ${
            open ? " rotate-180 " : ""
          }`}
        />
      </div>

      <Collapse isOpened={open}>
        <div className={` bg-[#0000001a] `}>
          {sub_items.map((elt: MenuItemProps) => {
            return <MenuItem className="pl-8" {...elt} key={elt.label} />;
          })}
        </div>
      </Collapse>
    </div>
  );
};

export default NestedItem;
