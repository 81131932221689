import { Student, Tutor, User } from "../Types/Users";

class UserModel implements User {
  id!: string | number;
  role!: string;
  data!: Tutor | Student;
  diplayed_role!: string;

  fullname() {
    return `${this.data.first_name || ""} ${this.data.last_name || ""}`;
  }

  getInitial() {
    return `${this.data?.first_name?.charAt(0)}${(
      this.data.last_name || ""
    )?.charAt(0)}`;
  }

  get civilityText() {
    return this.data.civility === "M" ? "Mr" : "Mme";
  }

  get birthDate() {
    return this.data.birth_date.split("T")[0];
  }
}

export default UserModel;
