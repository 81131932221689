import { useContext, useState } from "react";
import { NotifyContext } from "../../../context/notifyContext";
import { Post } from "../../../Types/Blogs";
import { BsExclamationTriangleFill } from "react-icons/bs";
import BlogAPI from "../../../services/api/BlogService";
import ButtonLoading from "../../../UI/Button/ButtonLoading";

interface HandleDeletePostProps {
    onClose?: () => void;
    initialState?: Post | null;
}

export default function HandleDeletePost(props: HandleDeletePostProps) {
    const { initialState } = props;

    const notify = useContext(NotifyContext);

    const [loading, setLoading] = useState<boolean>(false);

    const infos = {
        title: "Supprimer le post",
        message:
            "Êtes-vous sûr de vouloir supprimer cet article ? Il ne sera désormais plus visible dans votre Base de Donnees ",
        yes_label: "Supprimer",
        success_message: "Article Supprime",
    }

    const handleChange = () => {
        setLoading(true);
        console.log(initialState?.id )
        BlogAPI.delete_post(
            parseInt((initialState?.id || "").toString())
        )
            .then(() => {
                notify(infos.success_message, "success");
            })
            .catch((error) => {
                notify(error, "error");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                <div className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="sm:flex sm:items-start">
                        <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                            <BsExclamationTriangleFill
                                className="w-6 h-6 text-red-600"
                                aria-hidden="true"
                            />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <h3 className="text-base font-semibold leading-6 text-gray-900">
                                {infos.title}
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">{infos.message}</p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <ButtonLoading
                            type="button"
                            className={
                                "inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white  rounded-md shadow-sm  sm:ml-3 sm:w-auto"
                            }
                            color={"error"}
                            onClick={() => {
                                handleChange();
                            }}
                            loading={loading}
                            label={infos.yes_label}
                        />
                        <button
                            type="button"
                            className="inline-flex justify-center w-full px-3 py-2 mt-3 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={() => {
                                if (props.onClose) {
                                    props.onClose();
                                }
                            }}
                        >
                            Annuler
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
