import FormLabel from "../../../UI/Form/FormLabel";
import InputField from "../../../UI/Form/InputField";
import Button from "../../../UI/Button/Button";
import { CommunityComments } from "../../../Types/Community";

interface ShowCommentProps {
  onClose?: () => void;
  comment?: CommunityComments | null;
}

export default function ShowComment(props: ShowCommentProps) {
  const { comment } = props;

  return (
    <div>
      <div className="px-4 py-3">
        <div className={`mt-6 mb-6`}>
          <FormLabel htmlFor="content">Contenu</FormLabel>
          <div className="mt-1 rounded-md shadow-sm">
            <div
              id="content"
              dangerouslySetInnerHTML={{ __html: comment?.content ||  '' }}
              className="w-full form-input"
            ></div>
          </div>
        </div>

        <Button
          type="button"
          color="primary"
          onClick={() => {
            if (props.onClose) {
              props.onClose();
            }
          }}
        >
          Fermer
        </Button>
      </div>
    </div>
  );
}
