import React, { ReactNode, useState } from "react";
import { PostComment } from "../Types/Blogs";

export const PostCommentContext = React.createContext<{
  comment: PostComment | null | undefined;
  updatePostComment: (post: PostComment | null | undefined) => void;
} | null>(null);

export const PostCommentProvider = ({ children }: { children: ReactNode }) => {
  const [comment, setComment] = useState<PostComment | null | undefined>(null);
  const updatePostComment = (comment: PostComment | null | undefined) => {
    setComment(comment);
  };

  return (
    <PostCommentContext.Provider value={{ comment, updatePostComment }}>
      {children}
    </PostCommentContext.Provider>
  );
};
