import React, { useState } from "react";
import { MdSettings } from "react-icons/md";
import TitlePage from "../../UI/TitlePage/TitlePage";
import useBreadcrumbs from "../../utils/hooks/useBreadcrumbs";
import Website from "./Website";
import Admin from "./Admin";

export default function Settings() {
  useBreadcrumbs([
    {
      label: "Paramètres",
      icon: <MdSettings />,
      is_link: true,
      href: "/app/settings",
    },
  ]);

  const [tab, setTab] = useState<number>(1);

  return (
    <div>
      <TitlePage>
        <MdSettings size={18} />
        Paramètres
      </TitlePage>
      <div className="bg-white rounded shadow-md px-4 pb-4 mt-8">
        <div className="flex items-center gap-x-1  ">
          {[
            { label: "Site", tab_index: 1 },
            { label: "Admin", tab_index: 2 },
          ].map((item) => (
            <div
              className={`px-4 py-2 border text-sm  ${
                tab === item.tab_index
                  ? " border-transparent bg-white text-primary-800 "
                  : " border-slate-200 bg-slate-100 cursor-pointer "
              }`}
              key={item.label}
              onClick={() => setTab(item.tab_index)}
            >
              {item.label}
            </div>
          ))}
        </div>
        <div className="mt-2">
          {tab === 1 && <Website />}
          {tab === 2 && <Admin />}
        </div>
      </div>
    </div>
  );
}
