import { useContext, useEffect, useRef, useState } from "react";
import { FaBloggerB } from "react-icons/fa";
import useBreadcrumbs from "../../../utils/hooks/useBreadcrumbs";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormLabel from "../../../UI/Form/FormLabel";
import InputField from "../../../UI/Form/InputField";
import ButtonLoading from "../../../UI/Button/ButtonLoading";
import Alert from "../../../UI/Alert/Alert";
import { NotifyContext } from "../../../context/notifyContext";
import PostCommentContent from "./PostCommentContent";
import { Editor } from "@tinymce/tinymce-react";
import { PostCommentContext } from "../../../context/postCommentContext";
import { CommunityComments } from "../../../Types/Community";
import CommunityAPI from "../../../services/api/CommunityService";

interface AddOrUpdateCommentProps {
  onClose?: () => void;
  initialState?: CommunityComments | null;
}

export default function AddOrUpdateComment(props: AddOrUpdateCommentProps) {
  const editorRef = useRef<Editor | null>(null);

  useBreadcrumbs([
    {
      label: "Communauté",
      icon: <FaBloggerB />,
      is_link: true,
      href: "/app/community",
    },
    {
      label: "Commentaires",
      is_link: true,
      href: "/app/community/comments",
    },
    {
      label: "Nouveau",
      is_link: false,
    },
  ]);

  const { initialState } = props;

  const post_comment_store = useContext(PostCommentContext);

  useEffect(() => {
    if (initialState) {
      post_comment_store?.updatePostComment({ ...(initialState as CommunityComments) });
      // (editorRef.current as any).currentContent = post.content;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState]);

  const notify = useContext(NotifyContext);

  const validators = {
    title: Yup.string().required("Veuillez entrer le titre"),
  };

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape(validators),
    initialValues: {
      content: initialState?.content || "",
    },
    onSubmit: (values) => {
      alert('Mouf !')

      if (editorRef.current)
        values.content = (editorRef.current as any).getContent();

      setLoading(true);


      if (initialState) {
        CommunityAPI.update_post_comment({ ...initialState, ...values })
          .then((data) => {
            notify("Commentaire modifié avec succès");
            setError("");
            formik.resetForm();
            if (props.onClose) {
              props.onClose();
            }
          })
          .catch((error) => {
            setError(error);
            notify(error, "error");
          })
          .finally(() => {
            setLoading(false);
          });
      }
      // else {
      //   BlogAPI.add_category({ ...values })
      //     .then((data) => {
      //       notify("Catégorie crée avec succès");
      //       setError("");
      //       formik.resetForm();
      //       if (props.onClose) {
      //         props.onClose();
      //       }
      //     })
      //     .catch((error) => {
      //       setError(error);
      //       notify(error, "error");
      //     })
      //     .finally(() => {
      //       setLoading(false);
      //     });
      // }
    },
  });

  return (
    <div>
      <form className="px-4 py-3" onSubmit={formik.handleSubmit}>
        {error && <Alert color="error">{error}</Alert>}

        <div
          className={`mt-6 ${
            formik.touched.content && formik.errors.content && " text-red-500 "
          } `}
        >
          <PostCommentContent ref={editorRef} />

          {/* <FormLabel htmlFor="content">Contenu</FormLabel>

          <div className="mt-1 rounded-md shadow-sm">
            <textarea
              name={"content"}
              id="content"
              onBlur={formik.handleBlur}
              value={formik.values.content ?? ""}
              onChange={formik.handleChange}
              cols={30}
              rows={5}
              className="w-full form-input"
            ></textarea>
          </div> */}
          {
            <div className="mt-1 text-sm text-red-500">
              {" "}
              {formik.touched.content && formik.errors.content
                ? formik.errors.content
                : ""}
            </div>
          }
        </div>

        <ButtonLoading
          type="submit"
          loading={loading}
          label={initialState ? "Modifier" : "Enregistrer"}
        />
      </form>
    </div>
  );
}
