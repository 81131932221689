import clsx from "clsx";
import React, { HTMLAttributes } from "react";

export interface ButtonProps extends HTMLAttributes<HTMLDivElement> {
  color?: "primary" | "error";
}

export default function Alert(props: ButtonProps) {
  const { color } = props;

  let style = "";

  switch (color) {
    case "primary":
      style = " bg-primary text-black hover:bg-primary-800  ";
      break;
    case "error":
      style = " bg-red-100 text-red-500  ";
      break;

    default:
      break;
  }

  return (
    <div {...props} className={clsx("p-2 my-2", style, props.className)}>
      {props.children}
    </div>
  );
}
