import React, { ReactNode } from "react";

import {
  Slide,
  ToastContainer,
  ToastOptions,
  TypeOptions,
  toast,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const options: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  transition: Slide,
};

const notify = (message: string, type: TypeOptions = "success") => {
  if (type === "error") {
    toast.error(`${message}`, {
      ...options,
      style: { border: "5px solid red" },
    });
  } else if (type === "success") {
    toast.success(`${message}`, {
      ...options,
      style: { border: "5px solid green" },
    });
  } else if (type === "info") {
    toast.info(`${message}`, {
      ...options,
      style: { border: "5px solid blue" },
    });
  } else if (type === "warning") {
    toast.warning(`${message}`, {
      ...options,
      style: { border: "5px solid orange" },
    });
  }
};

export const NotifyContext = React.createContext<Function>(notify);

export const NotifyProvider = ({ children }: { children: ReactNode }) => {
  return (
    <NotifyContext.Provider value={notify}>
      {children}
      <ToastContainer newestOnTop={true} />
    </NotifyContext.Provider>
  );
};
