import React, { ButtonHTMLAttributes } from "react";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: "primary" | "error" | "info";
}

export default function Button(props: ButtonProps) {
  const { color } = props;

  let style = "";

  switch (color) {
    case "primary":
      if (props.disabled) {
        style = "  bg-gray-400 hover:bg-gray-400  ";
      } else {
        style = " bg-primary text-black hover:bg-primary-800  ";
      }
      break;
    case "error":
      style = " bg-red-500 text-white hover:bg-red-600  ";
      break;
    case "info":
      style = " bg-blue-500 text-white hover:bg-blue-600  ";
      break;

    default:
      style = " bg-primary text-black hover:bg-primary-800  ";
      break;
  }

  return (
    <button
      {...props}
      className={`${props.className} ${style}  flex items-center gap-2 justify-center text-center rounded transition duration-150 px-3 py-2 border border-transparent `}
      type={props.type || "button"}
    >
      {props.children}
    </button>
  );
}
